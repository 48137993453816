import { Select } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import { Drawer, Field, Layout } from '@loadsmart/miranda-react'
import { SelectedItemsTags } from 'fulfillments/components/Filters.components'
import type { Fulfillment } from 'fulfillments/domain/Fulfillment'
import { FULFILLMENT_STATUS_LABEL } from 'fulfillments/domain/Fulfillment'
import type {
  GetFulfillmentsFilters,
  FilterValues,
} from 'fulfillments/hooks/useFulfillmentList'
import { getDefaultFulfillmentsFiltersParams } from 'fulfillments/hooks/useFulfillmentList'
import { defaultTo, noop } from 'lodash'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

const STATUSES = Object.entries(FULFILLMENT_STATUS_LABEL).map(
  ([value, label]) => ({
    value: value as Fulfillment['status'],
    label,
  })
)

interface FiltersDrawerContentProps {
  readonly clearFilters: () => void
  readonly closeFiltersDrawer: () => void
  readonly filters: FilterValues
  readonly setFilters: (newFilters: FilterValues) => void
}

export function FiltersDrawerContent({
  clearFilters,
  closeFiltersDrawer,
  filters: currentFilters,
  setFilters: applyFilters,
}: FiltersDrawerContentProps) {
  const [filters, setFilters] = useState<GetFulfillmentsFilters>(
    getDefaultFulfillmentsFiltersParams(currentFilters)
  )

  const unselectItem = useCallback(
    (field: keyof GetFulfillmentsFilters, itemIndex: number) => {
      if (!Array.isArray(filters[field])) {
        return
      }

      const updatedField = filters[field]?.filter(
        (_: any, valIndex: number) => valIndex !== itemIndex
      )
      setFilters((prevFormData: any) => ({
        ...prevFormData,
        [field]: updatedField,
      }))
    },
    [filters, setFilters]
  )

  const handleStatusChange = useCallback(
    (
      event:
        | EventLike<Selectable | Selectable[] | null>
        | EventLike<[string | null, string | null] | null>
    ) => {
      const { value } = event.target

      if (Array.isArray(value)) {
        const values = value?.map((item) =>
          String((item as GenericOption).value)
        )

        setFilters((prevFormData) => ({
          ...prevFormData,
          status: defaultTo(values, null),
        }))
      }
    },
    [setFilters]
  )

  const handleApplyClick = useCallback(() => {
    applyFilters(filters)
    analytics.track(
      AnalyticsEvent.FulfillmentsListApplyFilters,
      AnalyticsEventTrigger.click,
      {
        status: filters.status,
      }
    )

    closeFiltersDrawer()
  }, [applyFilters, closeFiltersDrawer, filters])

  const handleClearFiltersClick = useCallback(() => {
    setFilters(getDefaultFulfillmentsFiltersParams())
    clearFilters()
  }, [clearFilters, setFilters])

  return (
    <Drawer
      onClose={closeFiltersDrawer}
      open
      size="medium"
      data-testid="filters"
    >
      <Drawer.Header>Apply Filters</Drawer.Header>
      <Drawer.Body>
        <Layout.Stack>
          <Layout.Stack gap="spacing-2">
            <Field>
              <Field.Label id="status">Status</Field.Label>
              <Select
                multiple
                options={STATUSES}
                name="statuses"
                id="statuses"
                placeholder="Select status"
                onChange={handleStatusChange}
                value={
                  filters.status?.map((value) => ({
                    lable:
                      FULFILLMENT_STATUS_LABEL[value as Fulfillment['status']],
                    value,
                  })) as Selectable[]
                }
              />
              <SelectedItemsTags
                items={defaultTo(filters.status, [])}
                getItemKey={(item) => item}
                onRemoveItem={(index) => unselectItem('status', index)}
                getLabel={(item) =>
                  FULFILLMENT_STATUS_LABEL[item as Fulfillment['status']]
                }
              />
            </Field>
          </Layout.Stack>
        </Layout.Stack>
      </Drawer.Body>
      <Drawer.Actions>
        <Layout.Group justify="space-between" style={{ width: '100%' }}>
          <Layout.Group gap="spacing-4">
            <Drawer.ActionPrimary
              data-testid="apply-filters"
              onClick={handleApplyClick}
              variant="primary"
              type="button"
            >
              Apply Filters
            </Drawer.ActionPrimary>
            <Drawer.ActionSecondary type="button" onClick={closeFiltersDrawer}>
              Close
            </Drawer.ActionSecondary>
          </Layout.Group>
          <Drawer.ActionSecondary
            data-testid="clear-filters"
            onClick={handleClearFiltersClick}
            type="button"
            variant="tertiary"
          >
            Clear Filters
          </Drawer.ActionSecondary>
        </Layout.Group>
      </Drawer.Actions>
    </Drawer>
  )
}

export interface FiltersDrawerProps {
  readonly clearFilters: () => void
  readonly closeFiltersDrawer: () => void
  readonly filters: FilterValues
  readonly isFiltersDrawerOpen: boolean
  readonly setFilters: (filters: FilterValues) => void
}

export function FiltersDrawer({
  clearFilters,
  closeFiltersDrawer,
  filters,
  isFiltersDrawerOpen,
  setFilters,
}: FiltersDrawerProps) {
  if (isFiltersDrawerOpen) {
    return (
      <FiltersDrawerContent
        clearFilters={clearFilters}
        closeFiltersDrawer={closeFiltersDrawer}
        filters={filters}
        setFilters={setFilters}
      />
    )
  }

  return null
}

interface ContextValue {
  closeFilters: () => void
  openFilters: () => void
}

const Context = createContext<ContextValue>({
  closeFilters: noop,
  openFilters: noop,
})

export function useFulfillmentsFiltersContext() {
  return useContext(Context)
}

export interface FulfillmentsFiltersProviderProps extends PropsWithChildren {
  readonly clearFilters: () => void
  readonly filters: FilterValues
  readonly setFilters: (newFilters: FilterValues) => void
}

export function FulfillmentsFiltersProvider({
  children,
  clearFilters,
  filters,
  setFilters,
}: FulfillmentsFiltersProviderProps) {
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false)
  const openFilters = useCallback(() => {
    setIsFiltersDrawerOpen(true)
  }, [setIsFiltersDrawerOpen])
  const closeFilters = useCallback(() => {
    setIsFiltersDrawerOpen(false)
  }, [setIsFiltersDrawerOpen])

  const contextValue = useMemo(
    () => ({ closeFilters, openFilters }),
    [closeFilters, openFilters]
  )

  return (
    <Context.Provider value={contextValue}>
      {children}

      <FiltersDrawer
        clearFilters={clearFilters}
        closeFiltersDrawer={closeFilters}
        filters={filters}
        isFiltersDrawerOpen={isFiltersDrawerOpen}
        setFilters={setFilters}
      />
    </Context.Provider>
  )
}
