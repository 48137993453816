import { getSuppliers } from 'fulfillments/fulfillment-service'
import { useQuery } from 'react-query'
import type { Supplier } from 'suppliers/types'

import { FulfillmentSelect } from './FulfillmentSelect'

const suppliersToOptions = (suppliers: Supplier[]) => {
  return suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.uuid,
  }))
}

export function FulfillmentSupplier({
  required,
}: {
  readonly required?: boolean
}) {
  const { data, isLoading } = useQuery({
    queryKey: ['get-suppliers'],
    queryFn: getSuppliers,
    cacheTime: 60000,
    select: suppliersToOptions,
  })

  return (
    <FulfillmentSelect
      name="supplier_uuid"
      label="Supplier"
      required={required}
      placeholder="Select supplier"
      loading={isLoading}
      options={data}
    />
  )
}
