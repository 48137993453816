import { mapFulfillmentStopsToTruckMileageStops } from 'fulfillments/fulfillment-utils'
import { useFulfillmentDetails } from 'fulfillments/hooks/useFulfillmentDetails'
import { isEmpty } from 'lodash'
import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useMemo } from 'react'
import type { QueryObserverOptions } from 'react-query'
import { useQuery } from 'react-query'

import { useCurrentShipperUUID } from '_shared_/user/useCurrentShipperUUID'
import { useTruckMileage } from 'orders/hooks/useTruckMileage'
import type { TruckMileageResponse } from 'orders/order-service'
import type { ListOrder } from 'orders/types'
import { useGetOrdersByUUID } from 'screens/Orders/common/useGetOrdersByUUID'
import { SHIPMENT_DETAILS_QUERY_KEY } from 'screens/Shipper/Shipments/Details/constants'
import { getShipmentDetails } from 'screens/Shipper/Shipments/Details/services/shipment'
import type { AdaptedShipment } from 'screens/Shipper/Shipments/Details/types'

import type { FulfillmentDetails } from './ViewFulfillmentPage.data'
import { enrichFulfillment } from './ViewFulfillmentPage.data'

const useShipmentDetails = (
  uuid: string = '',
  options?: QueryObserverOptions<AdaptedShipment>
) => {
  return useQuery<AdaptedShipment>({
    ...options,
    queryKey: [SHIPMENT_DETAILS_QUERY_KEY, uuid],
    queryFn: ({ signal }) => getShipmentDetails(uuid, { signal }),
    staleTime: Infinity,
  })
}

export interface ViewFulfillmentContext {
  fulfillment?: FulfillmentDetails
  isFulfillmentSupplier?: boolean
  isErrorLoadingFulfillment?: boolean
  isLoadingFulfillment?: boolean
  isLoadingOrders?: boolean
  isLoadingMileage?: boolean
  isLoadingShipment?: boolean
  mileage?: TruckMileageResponse
  orders?: ListOrder[] | null
  shipment?: AdaptedShipment
}

const Context = createContext<ViewFulfillmentContext>({})

export function useViewFulfillmentContextData(fulfillmentUUID: string) {
  const currentShipperUUID = useCurrentShipperUUID()
  const {
    data: rawFulfillment,
    isError: isErrorLoadingFulfillment,
    isLoading: isLoadingFulfillment,
  } = useFulfillmentDetails(fulfillmentUUID)

  const isFulfillmentSupplier = useMemo(() => {
    return (
      !isEmpty(rawFulfillment?.supplier?.uuid) &&
      rawFulfillment?.supplier?.uuid === currentShipperUUID
    )
  }, [rawFulfillment?.supplier?.uuid, currentShipperUUID])

  // Data processing
  const fulfillment = useMemo(
    () => enrichFulfillment(rawFulfillment),
    [rawFulfillment]
  )

  // Linked orders
  const ordersUUIDs = fulfillment?.order_uuids ?? []
  const { data, isLoading: isLoadingOrders } = useGetOrdersByUUID(
    ordersUUIDs,
    ordersUUIDs.length > 0
  )
  const orders = data?.results

  // Linked shipment
  // Not loading if user is supplier
  const {
    data: shipment,
    isLoading: isLoadingShipment,
    refetch: getShipment,
  } = useShipmentDetails(fulfillment?.shipment_uuid, { enabled: false })

  useEffect(() => {
    if (!isFulfillmentSupplier && !isEmpty(fulfillment?.shipment_uuid)) {
      getShipment()
    }
  }, [fulfillment?.shipment_uuid, getShipment, isFulfillmentSupplier])

  // Mileage
  const stops = useMemo(
    () => mapFulfillmentStopsToTruckMileageStops(rawFulfillment),
    [rawFulfillment]
  )
  const {
    data: mileage,
    isLoading: isLoadingMileage,
    refetch: getTruckMileage,
  } = useTruckMileage(stops)

  useEffect(() => {
    if (stops.length) {
      getTruckMileage()
    }
  }, [stops, getTruckMileage])

  return {
    fulfillment,
    isFulfillmentSupplier,
    isErrorLoadingFulfillment,
    isLoadingFulfillment,
    isLoadingOrders,
    isLoadingMileage,
    isLoadingShipment,
    mileage,
    orders,
    shipment,
  }
}

export function useViewFulfillmentContext() {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error(
      'useViewFulfillmentContext must be used within a ViewFulfillmentContextProvider'
    )
  }

  return context
}

export function ViewFulfillmentContextProvider({
  children,
  ...data
}: PropsWithChildren<Readonly<ViewFulfillmentContext>>) {
  return <Context.Provider value={data}>{children}</Context.Provider>
}
