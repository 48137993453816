import * as Sentry from '@sentry/react'
import { useState, useEffect } from 'react'

import { useSettings } from '_shared_/settings/useSettings'
import type { UserData } from '_shared_/user'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { USER_CATEGORIES } from 'utils/constants'
import DelightedLoader from 'utils/delighted'

const DELIGHTED_USER_TYPES = {
  FREEMIUM: 'Freemium',
  PREMIUM: 'Premium',
  FLATBED_MESSENGER: 'FBM',
  MANAGED_TRANS: 'MT',
}

const getUserType = (user: UserData) => {
  if (user?.business === 'MTC') {
    return DELIGHTED_USER_TYPES.MANAGED_TRANS
  }

  if (user?.business === 'FBM') {
    return DELIGHTED_USER_TYPES.FLATBED_MESSENGER
  }

  if (user?.user_category === USER_CATEGORIES.PAID_SHIPPER) {
    return DELIGHTED_USER_TYPES.PREMIUM
  }

  return DELIGHTED_USER_TYPES.FREEMIUM
}

const Delighted = () => {
  const {
    values: [delightedEnabled, delightedApiKey],
  } = useSettings(['flags.ENABLE_DELIGHTED', 'settings.DELIGHTED_API_KEY'])
  const { user } = useCurrentUser()
  const [delighted, setDelighted] = useState<Delighted | null>(null)
  const [surveyTriggered, setSurveyTriggered] = useState<boolean>(false)

  useEffect(() => {
    if (delightedEnabled && delightedApiKey && !user?.user_is_test) {
      try {
        const loader = new DelightedLoader(delightedApiKey)
        loader.load().then((_delighted: Delighted) => setDelighted(_delighted))
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }, [delightedEnabled, delightedApiKey, user])

  useEffect(() => {
    if (user && delighted && !surveyTriggered) {
      setSurveyTriggered(true)
      delighted.survey({
        email: user.user_email,
        name: user.user_name,
        createdAt: new Date(user.user_date_joined).toISOString(),
        properties: {
          test_user: user.user_is_test.toString(),
          role: user.user_role,
          company: user.shipper_company ?? '',
          user_type: getUserType(user),
        },
      })
    }
  }, [user, delighted, surveyTriggered])

  return null
}

export default Delighted
