import type { UserData } from '_shared_/user'

/**
 * @see {@link https://developers.google.com/tag-platform/tag-manager/datalayer#custom_data_layer_methods | Custom data layer methods}
 */
type CustomDataLayerMethods = {
  set: (key: string, value: unknown) => void
  get: (key: string) => unknown
  reset: () => void
}

type DataLayer = Array<
  Record<string, unknown> | ((this: CustomDataLayerMethods) => void)
>

type WindowWithDataLayer = Window & {
  dataLayer: DataLayer
}

// This strategy is used to avoid poluting the global scope, and to force the use of the `gtm` object
declare const window: WindowWithDataLayer

const getGtmVariable = () => {
  // prevent from failing in tests, but still allow tests to mock the dataLayer
  if (process.env.NODE_ENV === 'test' && !window.dataLayer) {
    window.dataLayer = []
  }

  // istanbul ignore next - this is a safety check for development environments only
  if (process.env.NODE_ENV !== 'production' && !window.dataLayer) {
    // istanbul ignore next - this is a safety check for development environments only
    throw new Error('You may have forgotten to load the GTM script')
  }

  return window.dataLayer
}

const trackEvent = (eventName: string, eventData?: Record<string, unknown>) => {
  getGtmVariable().push({
    event: eventName,
    ...eventData,
  })
}

export function gtmReset(this: CustomDataLayerMethods): void {
  this.reset()
}

/**
 * Google Tag Manager (GTM) analytics integration
 * @see {@link https://developers.google.com/tag-platform/tag-manager | Google Tag Manager}
 */
export const gtm = {
  trackEvent,
  identify(userData: UserData) {
    trackEvent('identify', {
      // User
      userEmail: userData.user_email,
      userIsMT: userData.is_managed_trans,
      userIsTest: userData.user_is_test,
      userName: userData.user_name,
      userRole: userData.user_role,
      userSignupDate: userData.user_date_joined,

      // Shipper
      customerName: userData.shipper_company,
      customerBusiness: userData.shipper.business,
      customerSegment: userData.shipper.segment,
      customerSignupDate: userData.shipper.signed_up_at,
      customerSubscription: userData.user_category,
    })
  },
  reset() {
    trackEvent('reset')
    getGtmVariable().push(gtmReset)
  },
}

/**
 * Load Google Tag Manager (GTM) script
 * Ideally, we should be adding the GTM script directly in the HTML file as high in the <head> of the page as possible.
 * However, since this is a CRA (react-scripts) project, we can't handle the conditional environment variables in the HTML.
 */
export const loadGTM = (gtmId: string) => {
  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })
  // we are sure that the document will have at least one script tag
  // remember that if this code was loaded it needed to be loaded through a script tag
  const firstScript = document.getElementsByTagName('script')[0]
  const newScript = document.createElement('script')
  newScript.async = true
  /**
   * If we want to, we can use a different name for the dataLayer variable.
   * This is useful when we want to use multiple GTM containers on the same page.
   * In this case, we need to set the `l` URL search parameter to the name we want to use and we need to update the index.html to safely initiate this attribute value.
   * @see {@link https://developers.google.com/tag-platform/tag-manager/datalayer#rename_the_data_layer | Rename the data layer}
   */
  newScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`

  /**
   * Document and DocumentFragment nodes can never have a parent, so parentNode will always return null.
   * It also returns null if the node has just been created and is not yet attached to the tree
   * Since we queried this element from the three and it is an HTMLScriptElement we use the non-null assertion operator `!` to avoid the TS error
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Node/parentNode | parentNode}
   */
  firstScript.parentNode!.insertBefore(newScript, firstScript)
}
