import { Button } from '@loadsmart/loadsmart-ui'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  InfoWindow,
} from '@react-google-maps/api'
import { useState } from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components'

import { useSettings } from '_shared_/settings/useSettings'
import MapOverlay from 'assets/imgs/map-overlay.jpg'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

export const Container = styled.div`
  align-items: center;
  background: url(${MapOverlay});
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  border-radius: ${toCSSValue('border-radius-m')};
`

const containerStyle = {
  width: '100%',
  height: '100%',
}

interface MapProps {
  readonly center: {
    lat: number
    lng: number
  }
  readonly zoom?: number
  readonly children?: ReactNode
  readonly onMapLoad?: (map: google.maps.Map) => void
  readonly isDisabled: boolean
}

const GOOGLE_MAPS_API_VERSION = '3.51'

function Map({ center, zoom = 7, children, onMapLoad, isDisabled }: MapProps) {
  const [isVisible, setIsVisible] = useState(false)
  const {
    values: [placesApiKey],
    isLoading,
  } = useSettings(['settings.GOOGLE_PLACES_API_KEY'])

  const mapsNotAvailable = isLoading || !placesApiKey || isDisabled

  return (
    <Container>
      {!isVisible ? (
        <Button
          variant="primary"
          onClick={() => {
            setIsVisible(true)
            analytics.track(
              AnalyticsEvent.ShipmentDetailsSeeMap,
              AnalyticsEventTrigger.click
            )
          }}
          disabled={mapsNotAvailable}
        >
          SEE THE MAP
        </Button>
      ) : null}
      {isVisible ? (
        <Container data-testid="map-container">
          <LoadScript
            googleMapsApiKey={placesApiKey}
            version={GOOGLE_MAPS_API_VERSION}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              options={{
                gestureHandling: 'cooperative',
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
              }}
              onLoad={onMapLoad}
            >
              {children}
            </GoogleMap>
          </LoadScript>
        </Container>
      ) : null}
    </Container>
  )
}

Map.Marker = Marker
Map.Polyline = Polyline
Map.InfoWindow = InfoWindow

export default Map
