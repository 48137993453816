import { Link } from 'react-router-dom'

import AuthPagesLogo from 'components/AuthPagesLogo'
import { useSearchParams } from 'hooks/useSearchParams'
import { appRoutes } from 'router/app-routes'
import analytics, { AnalyticsEvent } from 'utils/analytics'

import { HeaderStyled, NavigationLink, Navigation } from './styles'

export default function PublicHeader() {
  const [queryParams] = useSearchParams()

  const business = queryParams.get('business')
  const isFBMScreen = business === 'FBM'

  const getLoginURL = () => {
    if (isFBMScreen) {
      return appRoutes.fbmLogin
    }
    return appRoutes.login
  }

  return (
    <HeaderStyled>
      <Link
        to={getLoginURL()}
        onClick={() => analytics.track(AnalyticsEvent.NavbarLogo)}
      >
        <PublicHeaderLogo isFbm={isFBMScreen} />
      </Link>
      <Navigation>
        <NavigationAction loginUrl={getLoginURL()} />
      </Navigation>
    </HeaderStyled>
  )
}
function NavigationAction({ loginUrl }: { readonly loginUrl: string }) {
  return (
    <NavigationLink
      to={loginUrl}
      onClick={() => analytics.track(AnalyticsEvent.NavbarLogin)}
    >
      Login
    </NavigationLink>
  )
}
function PublicHeaderLogo({ isFbm }: { readonly isFbm: boolean }) {
  return <AuthPagesLogo isFbm={isFbm} variant="default" />
}
