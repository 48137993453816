// This version of sentry is compatible with workers
import * as Sentry from '@sentry/browser'
import type Pusher from 'pusher-js'

import ListenerRegistry from './ListenerRegistry'
import { SUBSCRIPTION_ERROR_EVENT, SUBSCRIPTION_SUCCESS_EVENT } from './pusher'
import type {
  OnReadyCallback,
  OnErrorCallback,
  OnMessageCallback,
  PusherSubscriptionError,
} from './pusher'

export default class Channel {
  name: string
  pusher: Pusher

  subscribed = false
  error?: PusherSubscriptionError

  private listeners = new ListenerRegistry()

  private onReadyCallbacks: OnReadyCallback[] = []
  private onErrorCallbacks: OnErrorCallback[] = []

  constructor(name: string, pusher: Pusher) {
    this.name = name
    this.pusher = pusher
  }

  subscribe(
    events: string | string[],
    onReady: OnReadyCallback,
    onError: OnErrorCallback,
    onMessage: OnMessageCallback
  ) {
    if (this.subscribed) {
      onReady()
    }

    if (this.error) {
      onError(this.error)
    }

    if (this.listeners.all().length === 0) {
      this.pusher
        .subscribe(this.name)
        .bind_global((ev: string, data?: unknown) => {
          if (ev === SUBSCRIPTION_SUCCESS_EVENT) {
            this.handleSubscriptionSuccess()

            this.onReadyCallbacks.forEach((cb) => cb())
          }

          if (ev === SUBSCRIPTION_ERROR_EVENT) {
            this.handleSubscriptionError(data as PusherSubscriptionError)

            this.onErrorCallbacks.forEach((cb) =>
              cb(data as PusherSubscriptionError)
            )
          }

          this.listeners.trigger(ev, data)
        })
    }

    this.onReadyCallbacks.push(onReady)
    this.onErrorCallbacks.push(onError)

    if (Array.isArray(events)) {
      events.forEach((event) => {
        this.listeners.add(event, onMessage)
      })
    } else {
      this.listeners.add(events, onMessage)
    }
  }

  unsubscribe(
    events: string | string[],
    onReady: OnReadyCallback,
    onError: OnErrorCallback,
    onMessage: OnMessageCallback
  ) {
    if (this.listeners.all().length === 0) {
      return
    }

    this.onReadyCallbacks = this.onReadyCallbacks.filter((cb) => cb !== onReady)
    this.onErrorCallbacks = this.onErrorCallbacks.filter((cb) => cb !== onError)

    if (Array.isArray(events)) {
      events.forEach((event) => {
        this.listeners.remove(event, onMessage)
      })
    } else {
      this.listeners.remove(events, onMessage)
    }

    if (this.listeners.all().length === 0) {
      this.pusher.unsubscribe(this.name)
      this.handleSubscriptionCancel()
    }
  }

  private handleSubscriptionSuccess() {
    this.subscribed = true
    this.error = undefined
  }

  private handleSubscriptionError(error: PusherSubscriptionError) {
    this.subscribed = false
    this.error = error

    Sentry.captureException(error)
  }

  private handleSubscriptionCancel() {
    this.subscribed = false
    this.error = undefined
  }
}
