import {
  EmptyState,
  Header,
  Layout,
  LogoLoader,
  PageContent,
} from '@loadsmart/miranda-react'
import { useFulfillmentDetails } from 'fulfillments/hooks/useFulfillmentDetails'
import { Helmet } from 'react-helmet'
import type { RouteComponentProps } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import GenericErrorIllustration from 'assets/illustrations/generic-error.svg'
import { MirandaPageGlobalStyle } from 'styles/global'

import { FulfillmentFormProvider } from '../components/FulfillmentFormContext'
import { EditFulfillmentForm } from './EditFulfillmentForm'

export function EditFulfillmentPage({
  match,
}: Readonly<RouteComponentProps<{ id: string }>>) {
  const history = useHistory()
  const { id } = match.params
  const { data: fulfillment, status, refetch } = useFulfillmentDetails(id)

  function handleBack() {
    history.goBack()
  }

  return (
    <>
      <Helmet>
        <title>Edit fulfillment | ShipperGuide</title>
      </Helmet>
      <MirandaPageGlobalStyle />
      <PageContent>
        <Header>
          <Header.BackButton onClick={handleBack} />
          <Header.Content>
            <Header.Title>Edit fulfillment</Header.Title>
          </Header.Content>
        </Header>
      </PageContent>
      <PageContent.Body>
        {status === 'loading' && (
          <Layout.Stack align="center" justify="center">
            <LogoLoader size="48" aria-busy="true" aria-label="Loading" />
          </Layout.Stack>
        )}
        {status === 'success' && (
          <FulfillmentFormProvider action="edit" fulfillment={fulfillment}>
            <EditFulfillmentForm />
          </FulfillmentFormProvider>
        )}
        {status === 'error' && (
          <Layout.Stack align="center" justify="center">
            <EmptyState
              variant="page"
              illustration={GenericErrorIllustration}
              header="Could not load fulfillment"
              message="Please try again"
              action="Retry"
              onAction={() => {
                refetch()
              }}
              data-testid="edit-fulfillment-empty-state"
            />
          </Layout.Stack>
        )}
      </PageContent.Body>
    </>
  )
}
