import { Link, Text } from '@loadsmart/miranda-react'
import type { FulfillmentDetailsHandlingUnit } from 'fulfillments/details/ViewFulfillmentPage.data'
import { generatePath } from 'react-router-dom'
import { Fragment } from 'react/jsx-runtime'

import { appRoutes } from 'router/app-routes'

export function FulfillmentHandlingUnitOrdersList({
  handlingUnit,
}: Readonly<{
  handlingUnit: FulfillmentDetailsHandlingUnit
}>) {
  const { orders } = handlingUnit

  return (
    <>
      {orders.map((order, index) => (
        <Fragment key={order.uuid}>
          {index !== 0 ? <>,</> : null}
          <Link
            href={generatePath(appRoutes.orderDetails, {
              orderId: order.uuid,
            })}
            size="small"
            target="_blank"
          >
            <Text color="color-primary-100" variant="body-sm-bold">
              {order.primary_ref}
            </Text>
          </Link>
        </Fragment>
      ))}
    </>
  )
}
