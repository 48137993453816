/**
 * ConnectionManager is a class to handle connection, disconnection and error events
 * It inherits from event emitter so it's also an event emitter.
 *
 * The idea is that it stores information about the connection status and errors.
 * When you add a new listener, it will automatically trigger it depending on the status of the connection.
 * We do this because Pusher only trigger these events once.
 * With this, we'll trigger them whenever a new listener is added.
 */

// This version of sentry is compatible with workers
import * as Sentry from '@sentry/browser'
import type Pusher from 'pusher-js'

import {
  CONNECTION_ERROR_EVENT,
  CONNECTION_SUCCESS_EVENT,
  DISCONNECTION_EVENT,
  CONNECTION_STATE_CHANGE_EVENT,
} from './pusher'
import type {
  OnErrorCallback,
  PusherConnectionError,
  PusherConnectionSuccess,
  PusherNotSupportedError,
  PusherUnavailableError,
} from './pusher'

export default class Connection {
  pusher: Pusher

  connected = false
  socketId?: string
  error?:
    | PusherConnectionError
    | PusherUnavailableError
    | PusherNotSupportedError

  private onErrorCallbacks: OnErrorCallback[] = []

  constructor(pusher: Pusher) {
    this.pusher = pusher

    this.pusher.connection
      .bind(CONNECTION_SUCCESS_EVENT, (data: PusherConnectionSuccess) => {
        this.handleConnectionSuccessEvent(data)
      })
      .bind(DISCONNECTION_EVENT, () => {
        this.handleDisconnectionEvent()
      })
      .bind(CONNECTION_ERROR_EVENT, (data: PusherConnectionError) => {
        this.handleConnectionErrorEvent(data)

        this.onErrorCallbacks.forEach((cb) => cb(data))
      })
      .bind(
        CONNECTION_STATE_CHANGE_EVENT,
        (states: { previous: string; current: string }) => {
          // Handle error states that are not consider a connection error
          // More information here: https://github.com/pusher/pusher-js#connection-states
          if (states.current === 'unavailable') {
            const error: PusherUnavailableError = {
              type: 'PusherUnavailableError',
              message:
                'Pusher Channels is unavailable or internet connection is down',
            }

            this.handleConnectionErrorEvent(error)
            this.onErrorCallbacks.forEach((cb) => cb(error))
          }

          if (states.current === 'failed') {
            const error: PusherNotSupportedError = {
              type: 'PusherNotSupportedError',
              message: 'Pusher Channels is not supported by the browser',
            }

            this.handleConnectionErrorEvent(error)
            this.onErrorCallbacks.forEach((cb) => cb(error))
          }
        }
      )
  }

  subscribe(onError: OnErrorCallback) {
    if (this.error) {
      onError(this.error)
    }

    if (this.pusher.connection.state === 'disconnected') {
      this.pusher.connect()
    }

    this.onErrorCallbacks.push(onError)
  }

  unsubscribe(onError: OnErrorCallback) {
    this.onErrorCallbacks = this.onErrorCallbacks.filter((cb) => cb !== onError)
  }

  end() {
    this.pusher.disconnect()
  }

  private handleConnectionSuccessEvent(data: PusherConnectionSuccess) {
    this.connected = true
    this.socketId = data.socket_id
    this.error = undefined
  }

  private handleConnectionErrorEvent(
    error:
      | PusherConnectionError
      | PusherUnavailableError
      | PusherNotSupportedError
  ) {
    this.connected = false
    this.socketId = undefined
    this.error = error

    Sentry.captureException(error)
  }

  private handleDisconnectionEvent() {
    this.connected = false
    this.socketId = undefined
    this.error = undefined
  }
}
