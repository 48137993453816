import { get } from 'lodash'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import type { QueryClient } from 'react-query'

import { triggerEvent } from '_shared_/custom-events/events'
import type { TransientFacilityV2 } from 'components/FacilityDrawer'
import type { FacilityDetailsV2 } from 'services/facilities'

function refetchShipperFacilities(queryClient: QueryClient) {
  queryClient.refetchQueries({
    queryKey: ['retrieveShipperFacilities', { sort: 'company_name' }],
  })
}

function refetchFacilityDetailsV2(
  facilityUUID: string,
  queryClient: QueryClient
) {
  queryClient.refetchQueries({
    queryKey: ['getFacilityDetailsV2', facilityUUID],
  })
}

/**
 * use this hook with the EventBasedFacilityDrawer and the EventBasedCreateFacilityDrawer.
 */
export function useFacilityDrawer(props?: {
  onFacilitySaved?: (facility: FacilityDetailsV2) => void
}) {
  const queryClient = useQueryClient()
  const onFacilitySaved = get(props, 'onFacilitySaved')

  /**
   * Opens the `CreateFacilityDrawer`.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openCreateFacilityForm = useCallback(
    (options?: {
      onFacilitySaved?: (facility: FacilityDetailsV2) => void
      initialValues?: Partial<TransientFacilityV2>
      customerUUID?: string
    }) => {
      const handleFacilitySaved = get(
        options,
        'onFacilitySaved',
        onFacilitySaved
      )

      triggerEvent('sg:open-create-facility-form', {
        initialValues: options?.initialValues,
        onFacilitySaved: (facility) => {
          refetchShipperFacilities(queryClient)

          handleFacilitySaved?.(facility)
        },
        customerUUID: options?.customerUUID,
      })
    },
    [onFacilitySaved, queryClient]
  )

  const openFacilityManagerDrawer = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetailsV2) => void
      initialView?: 'details' | 'edit-form'
    }) => {
      const { facilityUUID } = options

      const handleFacilitySaved = get(
        options,
        'onFacilitySaved',
        onFacilitySaved
      )

      triggerEvent('sg:open-facility-manager', {
        ...options,
        facilityUUID,
        onFacilitySaved: (facility) => {
          refetchShipperFacilities(queryClient)
          refetchFacilityDetailsV2(facilityUUID, queryClient)

          handleFacilitySaved?.(facility)
        },
      })
    },
    [onFacilitySaved, queryClient]
  )

  /**
   * Opens the `FacilityManagerDrawer` in the edit-form view.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openEditFacilityForm = useCallback(
    (options: {
      facilityUUID: string
      onFacilitySaved?: (facility: FacilityDetailsV2) => void
    }) => {
      openFacilityManagerDrawer({ initialView: 'edit-form', ...options })
    },
    [openFacilityManagerDrawer]
  )

  /**
   * Opens the `FacilityManagerDrawer` in the details view.
   * The `onFacilitySaved` callback provided here override the `onFacilitySaved` callback provided to the `useFacilityDrawer` hook (if any).
   */
  const openFacilityDetails = useCallback(
    (options: { facilityUUID: string }) => {
      openFacilityManagerDrawer({ initialView: 'details', ...options })
    },
    [openFacilityManagerDrawer]
  )

  return {
    openCreateFacility: openCreateFacilityForm,
    openEditFacility: openEditFacilityForm,
    openFacilityDetails,
  }
}
