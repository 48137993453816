import type { LabeledValueProps } from '@loadsmart/miranda-react'
import { LabeledValue, Layout, Link, Tag, Text } from '@loadsmart/miranda-react'
import type { LinkProps } from '@loadsmart/miranda-react/dist/components/Link/Link'
import { get, isEmpty, isUndefined, uniqBy } from 'lodash'
import type { PropsWithChildren } from 'react'
import { generatePath } from 'react-router-dom'

import type { HandlingUnitOrder } from 'components/CommodityDetails/Commodity'
import {
  DimensionsSummary,
  formatWeight,
  ShippingItemSummaryDimensions,
  ShippingItemSummaryFreightClass,
  ShippingItemSummaryPrimaryRefs,
} from 'components/ShippingItemsManager'
import { createSummarySetup, SummaryLabelValue } from 'components/Summary'
import { appRoutes } from 'router/app-routes'
import { plural } from 'utils/strings'
import {
  getTransientErrorsCount,
  getTransientErrorsCountFromArray,
} from 'utils/transient'

import type {
  HandlingUnitsDetails,
  TransientHandlingUnit,
} from './HandlingUnits.types'
import {
  calculateTotalWeight,
  formatPackageTypeLabel,
  getHighestFreightClassCommodity,
  hasHazmat,
  hasStackable,
  hasTurnable,
} from './HandlingUnitsUtils'

const { SummaryProvider, useSummaryContext } = createSummarySetup<
  TransientHandlingUnit | HandlingUnitsDetails
>({
  contextName: 'HandlingUnitsSummaryContext',
})

const HandlingUnitsSummaryHazmat = () => {
  const unit = useSummaryContext() as TransientHandlingUnit

  if (hasHazmat(unit.commodities)) {
    return (
      <Tag size="small" variant="warning">
        Hazmat
      </Tag>
    )
  }

  return null
}

const HandlingUnitsSummaryStackable = () => {
  const unit = useSummaryContext() as TransientHandlingUnit

  if (hasStackable(unit)) {
    return (
      <Tag size="small" variant="neutral">
        Stackable
      </Tag>
    )
  }

  return null
}

const HandlingUnitsSummaryTurnable = () => {
  const unit = useSummaryContext() as TransientHandlingUnit

  if (hasTurnable(unit)) {
    return (
      <Tag size="small" variant="neutral">
        Turnable
      </Tag>
    )
  }

  return null
}

const HandlingUnitsSummaryErrorsCountTag = () => {
  const unit = useSummaryContext() as TransientHandlingUnit
  const errorsCount =
    getTransientErrorsCount(unit) +
    getTransientErrorsCountFromArray(unit.commodities) +
    getTransientErrorsCountFromArray(unit.order_items || [])

  if (errorsCount) {
    return (
      <Tag size="small" variant="danger">
        {`${errorsCount} ${plural('error', errorsCount)}`}
      </Tag>
    )
  }

  return null
}

const HandlingUnitsSummaryFreightClass = () => {
  const unit = useSummaryContext() as TransientHandlingUnit

  const highestFreightClass = getHighestFreightClassCommodity(unit.commodities)

  return (
    <ShippingItemSummaryFreightClass
      inline
      freightClass={highestFreightClass}
    />
  )
}

const HandlingUnitsSummaryWeight = () => {
  const unit = useSummaryContext() as TransientHandlingUnit

  return (
    <SummaryLabelValue
      inline
      value={`Weighing ${formatWeight(calculateTotalWeight(unit))} in total`}
    />
  )
}

function HandlingUnitsSummaryCounter(props: { readonly counter: number }) {
  const { counter } = props

  return <SummaryLabelValue value={`${counter} H/U`} />
}

function ContextBasedHandlingUnitsSummaryCounter() {
  const unit = useSummaryContext()
  const counter = unit.commodities.length || 0

  return <HandlingUnitsSummaryCounter counter={counter} />
}

function ContextBasedHandlingUnitsOriginSummary() {
  const unit = useSummaryContext()

  const originIndex = (unit as HandlingUnitsDetails).pickup_stop_index

  if (isUndefined(originIndex)) {
    return null
  }

  const stopName = originIndex === 0 ? 'pickup' : `Stop#${originIndex}`

  return (
    <Text color="color-text-tertiary" variant="body-sm">
      From {stopName}
    </Text>
  )
}

function HandlingUnitOrderItemsRefsSummary({
  orders,
  getLabeledValueProps,
  getLinkProps,
}: Readonly<{
  orders: HandlingUnitOrder[]
  getLabeledValueProps: () => Partial<LabeledValueProps>
  getLinkProps: () => Partial<LinkProps>
}>) {
  return (
    <LabeledValue
      {...getLabeledValueProps()}
      data-testid="primary_refs-summary"
    >
      {orders?.length ? (
        <Layout.Group gap="spacing-1" slot="value">
          {orders.map((o) => (
            <Link
              key={o.uuid}
              {...getLinkProps()}
              target="_blank"
              href={generatePath(appRoutes.orderDetails, {
                orderId: o.uuid,
              })}
            >
              {o.primary_ref}
            </Link>
          ))}
        </Layout.Group>
      ) : (
        <Text variant="body-md" slot="value" color="color-text-primary">
          -
        </Text>
      )}
    </LabeledValue>
  )
}

function ContextBasedHandlingUnitsOrderItemsRefSummary({
  variant = 'default',
  label,
}: Readonly<{
  variant?: 'default' | 'large'
  label?: string
}>) {
  const unit = useSummaryContext()

  if (isEmpty((unit as TransientHandlingUnit).order_items)) {
    return null
  }

  const orders = uniqBy(
    get(unit, 'order_items', []).map((orderItem) => orderItem.order),
    'uuid'
  )

  const getLinkProps: () => Partial<LinkProps> = () =>
    variant === 'large' ? { size: 'default' } : { size: 'small' }

  const getLabelValueProps: () => Partial<LabeledValueProps> = () =>
    variant === 'large'
      ? { label: label ?? 'Order', labelVariant: 'body-md', inline: false }
      : { label: label ?? 'Order', labelVariant: 'body-sm', inline: true }

  return (
    <HandlingUnitOrderItemsRefsSummary
      getLinkProps={getLinkProps}
      getLabeledValueProps={getLabelValueProps}
      orders={orders}
    />
  )
}

function ContextBasedHandlingUnitsOrdersPrimaryRefSummary({
  label,
}: {
  readonly label?: string
}) {
  const unit = useSummaryContext()

  if (isEmpty(unit.orders)) {
    return null
  }

  return (
    <ShippingItemSummaryPrimaryRefs
      inline
      label={label ?? 'Order'}
      linkSize="small"
      orders={unit.orders}
    />
  )
}

function ContextBasedHandlingUnitsPOSummary() {
  const unit = useSummaryContext()

  const poNumbers = unit.orders?.map(({ po_number }) => po_number) ?? []

  if (isEmpty(poNumbers)) {
    return null
  }

  return (
    <Layout.Group gap="spacing-2">
      <Text variant="body-sm" color="color-text-tertiary">
        PO
      </Text>
      <Text variant="body-sm-bold" color="color-text-primary">
        {poNumbers.join(', ')}
      </Text>
    </Layout.Group>
  )
}

function ContextBasedHandlingUnitsSOSummary() {
  const unit = useSummaryContext()
  const soNumbers = unit.orders?.map(({ so_number }) => so_number) ?? []

  if (isEmpty(soNumbers)) {
    return null
  }

  return (
    <Layout.Group gap="spacing-2">
      <Text variant="body-sm" color="color-text-tertiary">
        SO
      </Text>
      <Text variant="body-sm-bold" color="color-text-primary">
        {soNumbers.join(', ')}
      </Text>
    </Layout.Group>
  )
}

function ContextBasedHandlingUnitsDescriptionSummary() {
  const unit = useSummaryContext()

  const commodities = unit.commodities || []

  const firstCommodityDescription =
    commodities.find((commodity) => commodity.description)?.description || null

  const addedCommoditiesDescription =
    commodities.length > 1 ? `+${commodities.length - 1}` : ''

  const aggregatedCommoditiesDescription = `${firstCommodityDescription}${addedCommoditiesDescription}`

  const commodityName = unit.package_type
    ? formatPackageTypeLabel(
        unit.package_type,
        Number(unit.package_count),
        'items',
        null
      )
    : 'Pallet'

  return (
    <Text variant="body-md-bold">
      {commodityName}
      {' of '}
      {firstCommodityDescription
        ? aggregatedCommoditiesDescription
        : (unit as HandlingUnitsDetails).commodity}
    </Text>
  )
}

function HandlingUnitsSummaryDimensions() {
  const unit = useSummaryContext()

  return (
    <ShippingItemSummaryDimensions
      inline
      length={unit.length}
      width={unit.width}
      height={unit.height}
    />
  )
}

export function HandlingUnitsSummary({
  value,
  children,
}: PropsWithChildren<{
  readonly value: TransientHandlingUnit | HandlingUnitsDetails
}>) {
  return (
    <SummaryProvider value={value}>
      <DimensionsSummary measurables={value.commodities}>
        {children}
      </DimensionsSummary>
    </SummaryProvider>
  )
}

const ContextBasedHandlingUnitsSummaryFulfillmentRefNumber = ({
  inline = true,
  labelVariant = 'body-sm',
}: {
  readonly inline?: boolean
  readonly labelVariant?: LabeledValueProps['labelVariant']
}) => {
  const handlingUnit = useSummaryContext() as TransientHandlingUnit
  if (!handlingUnit.fulfillment) {
    return null
  }
  return (
    <LabeledValue
      inline={inline}
      labelVariant={labelVariant}
      label="Fulfillment"
      data-testid="fulfillment_refs-summary"
    >
      <Layout.Group gap="spacing-1" slot="value">
        <Link
          size="default"
          key={handlingUnit.fulfillment.uuid}
          target="_blank"
          href={generatePath(appRoutes.fulfillmentDetails, {
            fulfillmentUuid: handlingUnit.fulfillment.uuid,
          })}
        >
          {handlingUnit.fulfillment.ref_number}
        </Link>
      </Layout.Group>
    </LabeledValue>
  )
}

HandlingUnitsSummary.Counter = ContextBasedHandlingUnitsSummaryCounter
HandlingUnitsSummary.Hazmat = HandlingUnitsSummaryHazmat
HandlingUnitsSummary.Stackable = HandlingUnitsSummaryStackable
HandlingUnitsSummary.Turnable = HandlingUnitsSummaryTurnable
HandlingUnitsSummary.FreightClass = HandlingUnitsSummaryFreightClass
HandlingUnitsSummary.ErrorsCountTag = HandlingUnitsSummaryErrorsCountTag
HandlingUnitsSummary.Dimensions = HandlingUnitsSummaryDimensions
HandlingUnitsSummary.Weight = HandlingUnitsSummaryWeight
HandlingUnitsSummary.Volume = DimensionsSummary.Volume
HandlingUnitsSummary.Density = DimensionsSummary.Density
HandlingUnitsSummary.LinearFeet = DimensionsSummary.LinearFeet
HandlingUnitsSummary.OrderItemsRefs =
  ContextBasedHandlingUnitsOrderItemsRefSummary
HandlingUnitsSummary.OrderPrimaryRefs =
  ContextBasedHandlingUnitsOrdersPrimaryRefSummary
HandlingUnitsSummary.PONumber = ContextBasedHandlingUnitsPOSummary
HandlingUnitsSummary.SONumber = ContextBasedHandlingUnitsSOSummary
HandlingUnitsSummary.Origin = ContextBasedHandlingUnitsOriginSummary
HandlingUnitsSummary.Description = ContextBasedHandlingUnitsDescriptionSummary
HandlingUnitsSummary.FulfillmentRefNumber =
  ContextBasedHandlingUnitsSummaryFulfillmentRefNumber
