export const appRoutes = {
  login: '/login',
  oAuth2Login: '/oauth2-login',
  oAuth2LoginCallback: '/oauth2-login-callback',
  signup: '/signup',
  forgotPassword: '/forgot_password',
  resetPassword: '/reset_password/:token',
  emailVerification: '/email_verification/:token?',
  pendingActivation: '/pending_activation',
  completeRegistration: '/complete_registration/:token',
  fbmSignUp: '/fbm-signup',
  fbmLogin: '/fbm-login',
  shipmentTracking: '/shipment-tracking/:shipmentId',
  logout: '/logout',
  shipments: '/shipments',
  autoTenderRules: '/shipments/auto-tender-rules',
  newShipment: '/shipments/add',
  quoteDetails: '/quotes/:quoteUUID',
  quotes: '/shipper/quotes',
  bulkInvite: '/bulk/invite/:bulkInviteUUID',
  bulkQuotesFromShipments: '/bulk/quotes-from-shipments/:bulkQuoteUUID',
  tenderConfirmation: '/carrier/tenders/:tenderUUID/confirm',
  newQuote: '/quotes/new/:quoteUUID?',
  shipmentDetails: '/shipments/:id',
  checkout: '/quote/:quoteUUID/checkout/:rateUUID',
  facilityManagement: '/facilities',
  contracts: '/contracts',
  newContract: '/contracts/add',
  editContract: '/contracts/edit/:contractId',
  contractDetails: '/contracts/:contractId',
  shipperReports: '/analytics/operations',
  userAgreement: '/user-agreement',
  priceMonitor: '/analytics/price',
  rfp: '/shipper/rfp',
  rfpReportDownload: '/shipper/rfp/:rfpId/download_report',
  newRFP: '/shipper/rfp/add',
  editRFP: '/shipper/rfp/:rfpId/edit',
  rfpDetails: '/shipper/rfp/:rfpId',
  carriers: '/shipper/carriers',
  lanes: '/shipper/lanes',
  orders: '/orders',
  orderDetails: '/orders/:orderId',
  planOrders: '/plan-orders',
  suppliersList: '/suppliers',
  shipperSettings: '/shipper/settings',
  freightWaves: '/freightwaves',
  editShipment: '/shipments/:shipmentId/edit',
  insights: '/insights',
  fulfillments: '/fulfillments',
  fulfillmentDetails: '/fulfillments/:fulfillmentUuid',
  payables: '/payables',
} as const
