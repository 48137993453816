import { Banner, Button, Layout } from '@loadsmart/miranda-react'
import { FulfillmentLane } from 'fulfillments/components/FulfillmentLane'
import { FulfillmentNotes } from 'fulfillments/components/FulfillmentNotes'
import { FulfillmentOrdersReviewCard } from 'fulfillments/components/FulfillmentOrdersReviewCard'
import type { TransientFulfillment } from 'fulfillments/domain/Fulfillment'
import { useGoToFulfillmentDetails } from 'fulfillments/hooks/navigation'
import { get } from 'lodash'
import { useEffect } from 'react'

import type { UserData } from '_shared_/user'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import type { PageIndices } from 'components/PageIndex'
import PageIndex from 'components/PageIndex'

import { FulfillmentFreightInfo } from '../components/FulfillmentFreightInfo'
import { useCreateFulfillmentForm } from './hooks/useCreateFulfillmentForm'
import { usePreSelection } from './hooks/usePreSelection'

export const getUserType = (shipper?: UserData['shipper']) => {
  if (shipper?.is_supplier) {
    return 'supplier'
  }
  return 'shipper'
}

export function CreateFulfillmentForm() {
  const { user } = useCurrentUser()
  const goToFulfillmentDetails = useGoToFulfillmentDetails()
  const {
    uuid,
    fulfillment,
    status,
    hasErrors,
    selectedOrders,
    submitForm,
    setPartialFulfillment,
  } = useCreateFulfillmentForm()
  // getting uuids from query params
  const { selectedOrdersUUIDs, clearSelectedOrders } = usePreSelection()

  const userType = getUserType(user?.shipper)
  const pageIndex: PageIndices = getPageIndex(
    fulfillment,
    Boolean(selectedOrders?.length)
  )

  // setting uuids on transient fulfillment
  useEffect(() => {
    if (selectedOrdersUUIDs.length) {
      setPartialFulfillment({ order_uuids: selectedOrdersUUIDs })
      clearSelectedOrders()
    }
  }, [clearSelectedOrders, selectedOrdersUUIDs, setPartialFulfillment])

  useEffect(() => {
    if (status === 'success' && uuid) {
      goToFulfillmentDetails(uuid)
    }
  }, [goToFulfillmentDetails, status, uuid])

  return (
    <form>
      <Layout.Container>
        <Layout.Row columnGap="spacing-8">
          <Layout.Column offsetLg="3" sm="12" md="8" lg="6">
            <Layout.Stack gap="spacing-6">
              {hasErrors && (
                <Banner variant="danger">
                  <Banner.Title>
                    Please fix the errors below to proceed
                  </Banner.Title>
                </Banner>
              )}
              <FulfillmentFreightInfo />
              <FulfillmentOrdersReviewCard />
              <FulfillmentLane userType={userType} />
              <FulfillmentNotes />
              <Layout.Group justify="flex-end">
                <Button type="button" variant="primary" onClick={submitForm}>
                  Create fulfillment
                </Button>
              </Layout.Group>
            </Layout.Stack>
          </Layout.Column>
          <Layout.Column sm="12" md="4" lg="3">
            <PageIndex values={pageIndex} />
          </Layout.Column>
        </Layout.Row>
      </Layout.Container>
    </form>
  )
}

export function getPageIndex(
  fulfillment: TransientFulfillment,
  hasSelectedOrders: boolean
) {
  const [pickup, delivery] = get(fulfillment, 'stops', [])

  const index = [
    { id: 'fulfillment-freight-info', label: 'Freight information' },
  ]

  if (fulfillment.order_identifier_type === 'orders' && hasSelectedOrders) {
    index.push({ id: 'fulfillment-orders', label: 'Orders' })
  }

  index.push({ id: 'fulfillment-lane', label: 'Lane' })

  if (pickup.facility) {
    index.push({ id: 'pickup', label: 'Pickup details' })
  }

  if (delivery.facility) {
    index.push({ id: 'delivery', label: 'Delivery details' })
  }

  index.push({ id: 'fulfillment-notes', label: 'Notes' })

  return index
}
