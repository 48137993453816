import { Header, PageContent } from '@loadsmart/miranda-react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

import { MirandaPageGlobalStyle } from 'styles/global'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

import { FulfillmentFormProvider } from '../components/FulfillmentFormContext'
import { CreateFulfillmentForm } from './CreateFulfillmentForm'

export function CreateFulfillmentPage() {
  const history = useHistory()

  function handleBack() {
    history.goBack()
  }

  useEffect(() => {
    analytics.track(
      AnalyticsEvent.FulfillmentsListView,
      AnalyticsEventTrigger.view
    )
  }, [])

  return (
    <>
      <Helmet>
        <title>New fulfillment | ShipperGuide</title>
      </Helmet>
      <MirandaPageGlobalStyle />
      <PageContent>
        <Header>
          <Header.BackButton onClick={handleBack} />
          <Header.Content>
            <Header.Title>New fulfillment</Header.Title>
          </Header.Content>
        </Header>
      </PageContent>
      <PageContent.Body>
        <FulfillmentFormProvider action="create">
          <CreateFulfillmentForm />
        </FulfillmentFormProvider>
      </PageContent.Body>
    </>
  )
}
