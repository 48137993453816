import { Button } from '@loadsmart/miranda-react'
import * as Sentry from '@sentry/react'
import { noop } from 'lodash'
import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { useSettings } from '_shared_/settings/useSettings'
import { ALLOWED_STATUSES_FOR_CONSOLIDATION } from 'orders/constants'
import type { ListOrder } from 'orders/types'

import { useGetOrdersByUUID } from '../common/useGetOrdersByUUID'
import { InvalidOrderSelectionForManualConsolidationDialog } from './components'
import { useRedirectToNewShipment } from './hooks'

interface ManualOrderConsolidationSelectionContextValue {
  isInvalidSelectionDialogOpen: boolean
  isManualOrderConsolidationEnabled: boolean
  isPlanPageEnabled: boolean
  onCancelContinueWithNewOrdersOnly: () => void
  onConfirmContinueWithNewOrdersOnly: () => void
  onNewShipmentFromSelectionClick: () => void
  selectedOrderUUIDs: string[]
}

const Context = createContext<ManualOrderConsolidationSelectionContextValue>({
  isInvalidSelectionDialogOpen: false,
  isManualOrderConsolidationEnabled: false,
  isPlanPageEnabled: false,
  onCancelContinueWithNewOrdersOnly: noop,
  onConfirmContinueWithNewOrdersOnly: noop,
  onNewShipmentFromSelectionClick: noop,
  selectedOrderUUIDs: [],
})

export function useManualOrderConsolidation(selectedOrderUUIDs: string[]) {
  const {
    values: [isManualOrderConsolidationEnabled, isPlanPageEnabled],
  } = useSettings([
    'flags.ENABLE_ORDERS_MANUAL_CONSOLIDATION',
    'flags.ENABLE_PLAN_ORDERS_PAGE',
  ])

  const { refetch: fetchOrderSelectionData } = useGetOrdersByUUID(
    selectedOrderUUIDs,
    false
  )
  const redirectToNewShipment = useRedirectToNewShipment()

  const [isInvalidSelectionDialogOpen, setIsInvalidSelectionDialogOpen] =
    useState<boolean>(false)
  const validOrders = useRef<ListOrder[]>([])

  const onNewShipmentFromSelectionClick = useCallback(async () => {
    const validationResult = await fetchOrderSelectionData({
      throwOnError: true,
    })

    const { data, error, isError, isLoading, isSuccess } = validationResult

    if (isError) {
      Sentry.captureException(error)
      toast.error('Unable to validate selected orders. Please try again.')

      return
    }

    if (!isLoading && isSuccess && data?.results?.length > 0) {
      const newValidOrders = data.results.filter((o) =>
        ALLOWED_STATUSES_FOR_CONSOLIDATION.includes(o.status)
      )

      validOrders.current = newValidOrders

      const isValid = newValidOrders.length === data.results.length

      if (isValid) {
        redirectToNewShipment(selectedOrderUUIDs)
      } else {
        setIsInvalidSelectionDialogOpen(true)
      }
    }
  }, [
    fetchOrderSelectionData,
    redirectToNewShipment,
    selectedOrderUUIDs,
    setIsInvalidSelectionDialogOpen,
    validOrders,
  ])

  const onCancelContinueWithNewOrdersOnly = useCallback(() => {
    setIsInvalidSelectionDialogOpen(false)
  }, [setIsInvalidSelectionDialogOpen])

  const onConfirmContinueWithNewOrdersOnly = useCallback(() => {
    redirectToNewShipment(validOrders.current.map((order) => order.uuid))
  }, [redirectToNewShipment, validOrders])

  return {
    isInvalidSelectionDialogOpen,
    isManualOrderConsolidationEnabled,
    isPlanPageEnabled,
    onCancelContinueWithNewOrdersOnly,
    onConfirmContinueWithNewOrdersOnly,
    onNewShipmentFromSelectionClick,
    selectedOrderUUIDs,
    validOrders,
  }
}

interface ManualOrderConsolidationProviderProps extends PropsWithChildren {
  readonly selectedOrderUUIDs: string[]
}

export function ManualOrderConsolidationProvider({
  children,
  selectedOrderUUIDs,
}: ManualOrderConsolidationProviderProps) {
  const contextValue = useManualOrderConsolidation(selectedOrderUUIDs)

  return (
    <Context.Provider value={contextValue}>
      {children}

      <InvalidOrderSelectionForManualConsolidationDialog
        isOpen={contextValue.isInvalidSelectionDialogOpen}
        onClose={contextValue.onCancelContinueWithNewOrdersOnly}
        onConfirm={contextValue.onConfirmContinueWithNewOrdersOnly}
        showConfirm={contextValue.validOrders.current?.length > 0}
      />
    </Context.Provider>
  )
}

export function NewShipmentFromOrderSelectionButton() {
  const {
    isManualOrderConsolidationEnabled,
    isPlanPageEnabled,
    onNewShipmentFromSelectionClick,
    selectedOrderUUIDs,
  } = useContext(Context)

  if (
    !isManualOrderConsolidationEnabled ||
    !isPlanPageEnabled ||
    !selectedOrderUUIDs?.length
  ) {
    return null
  }

  return (
    <Button
      onClick={onNewShipmentFromSelectionClick}
      variant="primary"
      data-testid="button-create-new-shipment"
    >
      Plan shipment from selection
    </Button>
  )
}
