import { Card, Layout, Table, Text, Tooltip } from '@loadsmart/miranda-react'
import { formatFulfilledCommodity } from 'fulfillments/fulfillment-utils'
import { generatePath, NavLink } from 'react-router-dom'

import { formatWeight } from 'components/ShippingItemsManager'
import { OrderStatusTag } from 'orders/components/OrderStatusTag'
import { formatOrderStop, formatOrderDates } from 'orders/orders.utils'
import type { ListOrder, Order } from 'orders/types'
import { appRoutes } from 'router/app-routes'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'
import type { OrderFulfillmentSummary } from '../ViewFulfillmentPage.data'

function LocationCell({
  order,
  stop,
}: Readonly<{
  order: Order | ListOrder
  stop: 'pickup' | 'delivery'
}>) {
  return (
    <Layout.Stack gap="none">
      {formatOrderStop(order, stop, false)}
      <Text variant="body-sm" color="color-text-tertiary">
        {formatOrderDates(order, stop)}
      </Text>
    </Layout.Stack>
  )
}

export function CommodityCell({
  summary,
}: Readonly<{
  summary?: OrderFulfillmentSummary
}>) {
  if (!summary) {
    return '-'
  }

  const items = Object.values(summary.items)
  const formattedTotalWeight = summary.totalShippedWeight
    ? formatWeight(summary.totalShippedWeight)
    : '-'

  if (summary.totalShippedItemsCount > 1) {
    return (
      <Layout.Stack gap="none">
        <Tooltip
          message={
            <Layout.Stack gap="none">
              {items.map((item) => (
                <Text key={item.uuid} color="color-text-inverted">
                  {formatFulfilledCommodity({
                    commodity: item.commodity,
                    orderedCount: item.package_count,
                    packageType: item.package_type,
                    customPackageType: item.custom_package_type,
                    shippedCount: item.totalShippedCount,
                  })}
                </Text>
              ))}
            </Layout.Stack>
          }
          placement="top"
          trigger="hover"
          data-testid="commodity-tooltip"
        >
          <Text color="color-text-link" variant="body-md-bold-underline">
            {items.length} commodities
          </Text>
        </Tooltip>
        <Text variant="body-sm" color="color-text-tertiary">
          {formattedTotalWeight}
        </Text>
      </Layout.Stack>
    )
  }

  const item = items[0]

  return (
    <Layout.Stack gap="none">
      {formatFulfilledCommodity({
        commodity: item.commodity,
        orderedCount: item.package_count,
        packageType: item.package_type,
        customPackageType: item.custom_package_type,
        shippedCount: item.totalShippedCount,
      })}
      <Text variant="body-sm" color="color-text-tertiary">
        {formattedTotalWeight}
      </Text>
    </Layout.Stack>
  )
}

export function FulfillmentOrdersCard() {
  const { fulfillment, orders } = useViewFulfillmentContext()

  if (!fulfillment || !orders?.length) {
    return null
  }

  return (
    <Card data-testid="fulfillment-orders">
      <Card.Title>Order</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Primary ref</Table.HeadCell>
              <Table.HeadCell>Origin</Table.HeadCell>
              <Table.HeadCell>Destination</Table.HeadCell>
              <Table.HeadCell>Shipped vs. ordered</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {orders.map((order) => (
              <Table.Row key={order.uuid}>
                <Table.Cell>
                  <NavLink
                    to={generatePath(appRoutes.orderDetails, {
                      orderId: order.uuid,
                    })}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      color="color-text-link"
                      variant="body-md-bold-underline"
                    >
                      {order.primary_ref}
                    </Text>
                  </NavLink>
                </Table.Cell>
                <Table.Cell>
                  <LocationCell order={order} stop="pickup" />
                </Table.Cell>
                <Table.Cell>
                  <LocationCell order={order} stop="delivery" />
                </Table.Cell>
                <Table.Cell>
                  <CommodityCell summary={fulfillment.summary[order.uuid]} />
                </Table.Cell>
                <Table.Cell>
                  <OrderStatusTag value={order.status} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  )
}
