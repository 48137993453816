import { Select } from '@loadsmart/loadsmart-ui'
import { Field, Text } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash'
import { useMemo } from 'react'
import type { CSSProperties } from 'react'

import type { FacilityContactV2 } from 'services/facilities'
import type { Transient } from 'utils/transient'

import { buildContactsDatasource } from './StopContact.helpers'
import type { TransientStop } from './Stops.types'
import type { UseStopFacilityParams } from './useStopFacility'
import { useStopFacility } from './useStopFacility'
import { useStopFormField } from './useStopFormField'
import type { UseStopFormFieldProps } from './useStopFormField'

const CUSTOM_COMPONENTS = {
  CreatableOption: function CreateContactOption() {
    return (
      <Select.CreatableOption>
        <Text variant="body-md-bold" color="color-accent-60">
          Add new contact
        </Text>
      </Select.CreatableOption>
    )
  },
}

type StopContactProps = Readonly<{
  label?: string
  required?: FieldProps['required']
  hint?: UseStopFormFieldProps['hint']
  error?: UseStopFormFieldProps['error']
  style?: CSSProperties
  addable?: boolean
}> &
  Pick<UseStopFacilityParams, 'onContactSaved'>

export const StopContact = (props: StopContactProps) => {
  const {
    label,
    required,
    hint,
    error,
    style,
    onContactSaved,
    addable = true, // defaulting to true in order to keep retrocompatibility
  } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    name,
    stop,
    dispatch,
  } = useStopFormField({ name: 'contact', hint, error })

  const { openContactsManager } = useStopFacility({ onContactSaved })

  const datasources = useMemo(
    () => {
      const contacts: FacilityContactV2[] = get(stop, 'facility.contacts', [])
      return [buildContactsDatasource(contacts)]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we are only interested in the facility changes; that's where we get the contacts from.
    [stop.facility]
  )

  const value = useMemo(() => {
    const contact = get<
      Transient<TransientStop>,
      string,
      FacilityContactV2 | null
    >(stop, name, null)

    if (contact == null) {
      return null
    }

    return {
      _type: 'contact',
      ...contact,
    }
  }, [name, stop])

  if (stop.location) {
    return null
  }

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>{label ?? 'Facility contact'}</Field.Label>

      <Select
        {...controlProps}
        isValidNewOption
        placeholder="Select a contact"
        createOptionPosition="first"
        name={name}
        datasources={datasources}
        disabled={get(stop, 'facility.uuid') == null}
        components={CUSTOM_COMPONENTS}
        value={value}
        onCreate={addable ? () => openContactsManager() : undefined}
        onChange={(event) => {
          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: get(event, 'target.value'),
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
