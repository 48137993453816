import {
  Button,
  Dropdown,
  Header,
  Icon,
  Layout,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { useGoToNewFulfillmentWithOrders } from 'fulfillments/hooks/navigation'
import { useIsFulfillmentsFlowEnabled } from 'fulfillments/hooks/useIsFulfillmentsFlowEnabled'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { HeaderBackLink } from '_shared_/components/HeaderBackLink'
import { useSettings } from '_shared_/settings/useSettings'
import { useOrderHistoryContext } from 'orders/components/OrderHistory'
import { OrderStatusTag } from 'orders/components/OrderStatusTag'
import {
  formatOrderDates,
  formatOrderSource,
  formatOrderStop,
} from 'orders/orders.utils'
import type { Order } from 'orders/types'
import { appRoutes } from 'router/app-routes'
import { isOrderPlannable } from 'screens/Orders/common/utils'

import { useViewOrderContext } from '../ViewOrderContext'

export function ActionsDropdown({ order }: Readonly<{ order: Order }>) {
  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()
  const goToNewFulfillment = useGoToNewFulfillmentWithOrders()
  const history = useHistory()
  const isPlannable = isOrderPlannable(order)

  const {
    values: [isManualConsolidationEnabled, isPlanPageEnabled],
  } = useSettings([
    'flags.ENABLE_ORDERS_MANUAL_CONSOLIDATION',
    'flags.ENABLE_PLAN_ORDERS_PAGE',
  ])

  if (isFulfillmentsFlowEnabled) {
    return (
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            goToNewFulfillment([order.uuid])
          }}
        >
          <Layout.Stack gap="spacing-0-5">
            <Text>Create a fulfillment...</Text>
            <Text variant="body-sm" color="color-text-tertiary">
              Create a fulfillment for this order
            </Text>
          </Layout.Stack>
        </Dropdown.Item>
      </Dropdown.Menu>
    )
  }

  if (!isManualConsolidationEnabled || !isPlanPageEnabled) {
    return null
  }

  return (
    <Dropdown.Menu>
      <Dropdown.Item
        disabled={!isPlannable}
        onClick={() => {
          history.push(
            generatePath(`${appRoutes.newShipment}?selected=:orderId`, {
              orderId: order.uuid,
            })
          )
        }}
      >
        <Layout.Stack gap="spacing-0-5">
          <Text>Plan as shipment...</Text>
          <Text variant="body-sm" color="color-text-tertiary">
            Create a shipment including this order
          </Text>
        </Layout.Stack>
      </Dropdown.Item>
    </Dropdown.Menu>
  )
}

export function ViewOrderPageHeader() {
  const {
    isErrorLoadingOrder,
    isOrderSupplier,
    isLoadingOrder,
    mileage,
    order,
  } = useViewOrderContext()
  const { openDrawer } = useOrderHistoryContext()

  const onShowHistoryClick = useCallback(() => {
    openDrawer(order?.uuid)
  }, [order?.uuid, openDrawer])

  if (isErrorLoadingOrder || isLoadingOrder || !order) {
    return (
      <Header>
        <HeaderBackLink href={appRoutes.orders} />
        <Header.Content>
          <Header.Title>
            <Layout.Group gap="none">Order</Layout.Group>
          </Header.Title>
        </Header.Content>
      </Header>
    )
  }

  return (
    <Header>
      <HeaderBackLink href={appRoutes.orders} />
      <Header.Content>
        <Layout.Group justify="space-between" style={{ width: '100%' }}>
          <Layout.Stack gap="spacing-2">
            <Layout.Stack gap="spacing-1">
              <Layout.Group align="center" gap="spacing-6">
                <Layout.Group align="center" gap="spacing-1">
                  <Text variant="body-lg-bold">{`Order ${order?.primary_ref}`}</Text>
                  <Text variant="body-lg-bold">&bull;</Text>
                  <Text variant="body-lg-bold">
                    {formatOrderStop(order, 'pickup')}
                  </Text>
                  <Text variant="body-lg-bold">&rarr;</Text>
                  <Text variant="body-lg-bold">
                    {formatOrderStop(order, 'delivery')}
                  </Text>
                </Layout.Group>
                <OrderStatusTag value={order.status} size="default" />
              </Layout.Group>

              <Layout.Group align="center" gap="spacing-2">
                <Text variant="heading-sm-bold" color="color-text-tertiary">
                  {formatOrderDates(order, 'pickup')}
                </Text>

                <Text variant="heading-sm-bold">&rarr;</Text>

                <Text variant="heading-sm-bold" color="color-text-tertiary">
                  {formatOrderDates(order, 'delivery')}
                </Text>

                {mileage && (
                  <>
                    <Text color="color-text-tertiary" variant="heading-sm-bold">
                      &bull;
                    </Text>
                    <Text
                      variant="heading-sm-bold"
                      color="color-text-tertiary"
                    >{`${mileage?.total_miles} miles`}</Text>
                  </>
                )}
              </Layout.Group>
            </Layout.Stack>

            <Layout.Group align="center" gap="spacing-2">
              <Text variant="body-sm" color="color-text-placeholder">
                {formatOrderSource(order)}
              </Text>

              {!isOrderSupplier && (
                <Tooltip message="Show history" placement="top" trigger="hover">
                  <Button
                    type="button"
                    variant="icon-secondary"
                    size="small"
                    aria-label="Show history"
                    onClick={onShowHistoryClick}
                  >
                    <Icon name="clock" size="14px" />
                  </Button>
                </Tooltip>
              )}
            </Layout.Group>
          </Layout.Stack>

          <Layout.Stack gap="spacing-2" align="flex-end">
            <Layout.Group>
              <Dropdown position="fixed">
                <Tooltip message="More actions" placement="top">
                  <Button
                    variant="icon-secondary"
                    aria-label="Open order actions menu"
                    id="order_actions_trigger"
                    aria-haspopup="true"
                    tabIndex={0}
                  >
                    <Icon name="dots-vertical" />
                  </Button>
                </Tooltip>
                <ActionsDropdown order={order} />
              </Dropdown>
            </Layout.Group>
          </Layout.Stack>
        </Layout.Group>
      </Header.Content>
    </Header>
  )
}
