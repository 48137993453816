import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { lowerCase, startCase, template, upperCase } from 'lodash'

import type { SelectableLocation } from 'contracts/components/FiltersDrawer'
import { getCurrentTimezone } from 'utils/dateUtils'

import { cleanEmptyValues } from './cleanEmptyValues'

const ADDRESS = '${address}'
const CITY = '${city}'
const COUNTRY = '${country}'
const STATE = '${state}'
const ZIPCODE = '${zipcode}'
const ZIPCODE_END = '${zipcode_end}'
const ZIP3 = '${zip3}'
const KMA = '${kma}'

const DEFAULT_FORMAT = `${CITY}, ${STATE} ${ZIPCODE}, ${COUNTRY}`
const formats: { [key: string]: any } = {}

interface LocationArgs {
  country?: string | null
  kma?: string | null
  state?: string | null
  city?: string | null
  zip3?: string | null
  zipcode?: string | null
  zipcode_end?: string | null
  address?: string | null
  place_id?: string | null
  _type?: string | null
  uuid?: string | null
  company_name?: string | null
  facility_name?: string | null
  name?: string | null
}

interface LocationObjectArgs {
  timezone?: string | null
}

function getCompiledFormat(format: string): string {
  if (!formats[format]) {
    formats[format] = template(format)
  }

  return formats[format]
}

function prettify(text: string) {
  return startCase(lowerCase(text ?? ''))
}

export function getLocationOption(location: LocationArgs): GenericOption {
  const withFacility = location._type === 'facility' || location?.facility_name
  const facilityName =
    location.company_name || location?.facility_name || location?.name
  //The location could be an google address or a facility, using address as value is more generic
  const value = location.address
  const formattedLabel = getDefaultFormattedLocation(location)

  return {
    value: value ? String(value) : formattedLabel,
    label: withFacility ? facilityName : formattedLabel,
  }
}

export const cleanLocations = (locations: SelectableLocation[] | null) =>
  (locations ?? [])
    .map(({ address: _, _type, ...location }) => location)
    .map(cleanEmptyValues) as SelectableLocation[]

function getDefaultFormattedLocation(location: LocationArgs) {
  if (location.kma) {
    return LocationHelper(location).format(
      `${LocationHelper.KMA} - ${LocationHelper.ADDRESS}`
    )
  }

  if (location.address) {
    return LocationHelper(location).format(LocationHelper.ADDRESS)
  }

  if (location.city) {
    return LocationHelper(location).format(
      `${LocationHelper.CITY}, ${LocationHelper.STATE}, ${LocationHelper.COUNTRY}`
    )
  }

  if (location.zipcode) {
    return LocationHelper(location).format(
      `${LocationHelper.ZIPCODE}, ${LocationHelper.COUNTRY}`
    )
  }

  if (location.zip3) {
    return LocationHelper(location).format(
      `${LocationHelper.ZIP3}, ${LocationHelper.COUNTRY}`
    )
  }

  if (location.state) {
    return LocationHelper(location).format(
      `${LocationHelper.STATE}, ${LocationHelper.COUNTRY}`
    )
  }

  if (location.country) {
    return LocationHelper(location).format(`${LocationHelper.COUNTRY}`)
  }

  return 'All Countries'
}

export function getTimezoneFromLocationObject(location: LocationObjectArgs) {
  if (!location.timezone) {
    return getCurrentTimezone()
  }
  return location.timezone
}

type LatLngLocation = {
  lat: number
  lng: number
}

/**
 * Calculates the distance in kilometers between two locations.
 *
 * based on https://en.wikipedia.org/wiki/Haversine_formula
 *
 * @param locationA - The coordinates of the first location.
 * @param locationB - The coordinates of the second location.
 * @returns The distance in kilometers.
 */
export function getDistanceInKMBetweenLocations(
  locationA: LatLngLocation,
  locationB: LatLngLocation
): number {
  const RADIUS_OF_THE_EARTH_IN_KM = 6371
  const radian = Math.PI / 180
  const latDiff = (locationB.lat - locationA.lat) * radian
  const lngDiff = (locationB.lng - locationA.lng) * radian
  const a =
    0.5 -
    Math.cos(latDiff) / 2 +
    (Math.cos(locationA.lat * radian) *
      Math.cos(locationB.lat * radian) *
      (1 - Math.cos(lngDiff))) /
      2
  const distance = 2 * RADIUS_OF_THE_EARTH_IN_KM * Math.asin(Math.sqrt(a))
  return Number(distance.toFixed(2))
}

/**
 * ```js
 * import LocationHelper from '<path>'
 *
 * // returns '123 St Street, Neverland, XYZ/CO'
 * LocationHelper({
 *  address: '123 St Street',
 *  city: 'Neverland',
 *  state: 'XYZ',
 *  country: 'CO',
 * }).format(`${LocationHelper.ADDRESS}, ${LocationHelper.CITY}, ${LocationHelper.STATE}/${LocationHelper.Country}`)
 * ```
 */
function LocationHelper(locationArg: LocationArgs) {
  const location = {
    ...(locationArg || {}),
    address: locationArg?.address ?? '-',
    city: prettify(locationArg?.city ?? 'All cities'),
    country: locationArg?.country
      ? upperCase(locationArg?.country)
      : 'All Countries',
    state:
      locationArg?.state != null ? upperCase(locationArg.state) : 'All States',
    zipcode: prettify(locationArg?.zipcode ?? '-'),
  }

  return {
    /**
     * Get location formatted with the default or the provided format.
     */
    format(format = DEFAULT_FORMAT) {
      const compiled = getCompiledFormat(format) as any
      return compiled(location)
    },
  }
}

LocationHelper.ADDRESS = ADDRESS
LocationHelper.CITY = CITY
LocationHelper.COUNTRY = COUNTRY
LocationHelper.STATE = STATE
LocationHelper.ZIPCODE = ZIPCODE
LocationHelper.ZIPCODE_END = ZIPCODE_END
LocationHelper.ZIP3 = ZIP3
LocationHelper.KMA = KMA

export default LocationHelper
