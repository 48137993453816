import type { AxiosError } from 'axios'
import { get } from 'lodash'
import { useMutation } from 'react-query'

import type { FacilityDetailsV2 } from 'services/facilities'
import { createFacility } from 'services/facilities'
import toArray from 'utils/toArray'

import {
  buildFacilityPayload,
  buildFacilitySupplierPayload,
} from './Facility.helpers'
import type { TransientFacilityV2 } from './Facility.types'

export const useCreateNewFacility = ({
  onSuccess,
  onError,
  isSupplier,
  customerUUID,
}: {
  onSuccess: (savedFacility: FacilityDetailsV2) => void
  onError: (errors: string[]) => void
  isSupplier?: boolean
  customerUUID?: string
}) => {
  const { mutate, isLoading } = useMutation<
    FacilityDetailsV2,
    AxiosError<{ errors?: string[] }>,
    TransientFacilityV2
  >({
    mutationFn: (facility: TransientFacilityV2) => {
      return createFacility({
        facility:
          isSupplier && customerUUID
            ? buildFacilitySupplierPayload(facility, customerUUID)
            : buildFacilityPayload(facility),
      })
    },
    onSuccess,
    onError: (error) => {
      onError(toArray(get(error, 'response.data.errors')))
    },
  })

  return { createFacility: mutate, isSaving: isLoading }
}
