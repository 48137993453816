import { mapFulfillmentFromOrder } from 'fulfillments/domain/Fulfillment.mapper'
import { get, set } from 'lodash'
import type { Dispatch, SetStateAction } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useFulfillableOrders } from 'orders/hooks/useFulfillableOrders'
import type { ListOrder } from 'orders/types'
import { hasTransientError } from 'utils/transient'

import type {
  Fulfillment,
  FulfillmentFormAction,
  TransientFulfillment,
} from '../domain/Fulfillment'
import { hydrateTransientFulfillment } from '../domain/Fulfillment'
import { validate } from '../domain/Fulfillment.validation'

export type FulfillmentFormContextValue = {
  fulfillment: TransientFulfillment
  setPartialFulfillment: (value: Partial<TransientFulfillment>) => void
  setFulfillment: Dispatch<SetStateAction<TransientFulfillment>>
  action: FulfillmentFormAction
  orders: ListOrder[]
  isLoadingOrders?: boolean
  firstSelectedOrder?: ListOrder | null
  selectedOrders?: ListOrder[] | null
}

export const FulfillmentFormContext = createContext<
  FulfillmentFormContextValue | undefined
>(undefined)

export function useFulfillmentFormContext() {
  const context = useContext(FulfillmentFormContext)

  if (context === undefined) {
    throw new Error(
      'useFulfillmentFormContext must be used within a FulfillmentFormContext.Provider'
    )
  }

  return context
}

export function FulfillmentFormProvider({
  action = 'create',
  fulfillment,
  children,
}: Readonly<{
  action?: FulfillmentFormContextValue['action']
  fulfillment?: Fulfillment
  children: React.ReactNode
}>) {
  const [transientFulfillment, setTransientFulfillment] =
    useState<TransientFulfillment>(hydrateTransientFulfillment(fulfillment))

  const setPartialFulfillment = useCallback(
    (partialState: Partial<TransientFulfillment>) => {
      setTransientFulfillment((currentFulfillment) => {
        const updatedFulfillment = Object.keys(partialState).reduce(
          (newFulfillment, path) => {
            return set(newFulfillment, path, get(partialState, path))
          },
          { ...currentFulfillment }
        )

        if (hasTransientError(updatedFulfillment)) {
          const [validatedFulfillment] = validate(updatedFulfillment)
          return validatedFulfillment
        }

        return updatedFulfillment
      })
    },
    [setTransientFulfillment]
  )

  const asSupplier = transientFulfillment.owner === 'customer'

  const { orders, isLoading: isLoadingOrders } = useFulfillableOrders({
    asSupplier,
    enabled: action === 'create',
  })

  const firstSelectedOrder = useMemo(() => {
    const UUIDs = transientFulfillment.order_uuids

    if (UUIDs != null && UUIDs.length > 0) {
      return orders.find((order) => order.uuid === UUIDs[0])
    }

    return null
  }, [transientFulfillment.order_uuids, orders])

  const selectedOrders = useMemo(() => {
    const UUIDs = transientFulfillment.order_uuids

    if (UUIDs != null) {
      return orders.filter((order) => UUIDs.includes(order.uuid))
    }

    return null
  }, [transientFulfillment.order_uuids, orders])

  useEffect(() => {
    if (!firstSelectedOrder) {
      return
    }

    const partialFulfillment = mapFulfillmentFromOrder(
      firstSelectedOrder,
      asSupplier
    )

    if (!partialFulfillment) {
      return
    }

    setPartialFulfillment(partialFulfillment)
  }, [asSupplier, firstSelectedOrder, setPartialFulfillment])

  const value = useMemo(() => {
    return {
      action,
      firstSelectedOrder,
      fulfillment: transientFulfillment,
      setFulfillment: setTransientFulfillment,
      setPartialFulfillment,
      orders,
      isLoadingOrders,
      selectedOrders,
    }
  }, [
    action,
    firstSelectedOrder,
    transientFulfillment,
    setPartialFulfillment,
    orders,
    isLoadingOrders,
    selectedOrders,
  ])

  return (
    <FulfillmentFormContext.Provider value={value}>
      {children}
    </FulfillmentFormContext.Provider>
  )
}
