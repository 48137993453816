import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'
import { defaultTo, isEmpty, uniq, uniqBy } from 'lodash'

import type { PackageType } from 'components/ShippingItemsManager'
import {
  calculateTotalVolume,
  formatWeight,
} from 'components/ShippingItemsManager'
import type { TruckMileageStop } from 'orders/order-service'
import { getFormattedPackageType } from 'orders/orders.utils'
import type {
  OrderFulfillmentsListItem,
  OrderFulfillmentsListItemHandlingUnit,
  OrderItem,
} from 'orders/types'
import { PACKAGE_TYPE_LABELS } from 'screens/NewQuote/LTLQuote/LTLQuote.constants'
import { formatDate } from 'utils/dateUtils'
import LocationHelper from 'utils/locations'
import { numberFormatter } from 'utils/numbers'
import { formatPhoneNumber } from 'utils/phone'
import { defaultEmptyString, plural } from 'utils/strings'

import type {
  FulfillmentDetails,
  FulfillmentDetailsHandlingUnit,
} from './details/ViewFulfillmentPage.data'
import type {
  Fulfillment,
  FulfillmentBaseFacility,
  FulfillmentHandlingUnit,
  FulfillmentHandlingUnitTypes,
  FulfillmentTypes,
  ListFulfillment,
  ListFulfillmentFacility,
  ListFulfillmentHandlingUnit,
} from './domain/Fulfillment'

/**
 * @param stop Fulfillment pickup or delivery object
 * @param includeZipCode If true, the zipcode will be appended to the base format (<city>, <state>). Defaults to true
 * @returns Formatted location address
 */
export function formatFulfillmentStop(
  stop:
    | Fulfillment['pickup_facility']
    | Fulfillment['delivery_facility']
    | FulfillmentBaseFacility
    | ListFulfillmentFacility,
  includeZipCode = true
) {
  let formattedLocation = '-'
  const location: Record<string, string> = {
    state: stop.state,
    city: stop.city,
  }
  let format = `${LocationHelper.CITY}, ${LocationHelper.STATE}`

  if (includeZipCode && 'zipcode' in stop) {
    format = `${LocationHelper.CITY}, ${LocationHelper.STATE} ${LocationHelper.ZIPCODE}`
    location.zipcode = stop.zipcode
  }

  if (!Object.values(location).every(isEmpty)) {
    formattedLocation = LocationHelper(location).format(format)
  }

  return formattedLocation
}

export function formatFulfillmentPickupReadyDate(
  fulfillment:
    | Fulfillment
    | ListFulfillment
    | Pick<Fulfillment, 'pickup_ready_date'>,
  dateFormat = 'ddd, MMM D'
) {
  const dateFormatter = DateFormatHelper(dateFormat)

  return `${dateFormatter.format(DateHelper(fulfillment.pickup_ready_date))}`
}

export function formatFulfillmentDeliveryDate(
  fulfillment: Fulfillment | ListFulfillment,
  dateFormat = 'ddd, MMM D'
) {
  const dateFormatter = DateFormatHelper(dateFormat)

  return fulfillment.delivery_date
    ? `${dateFormatter.format(DateHelper(fulfillment.delivery_date))}`
    : 'No delivery date'
}

export function formatFulfillmentDates(
  fulfillment: Fulfillment,
  dateFormat = 'MMM D'
) {
  const dateFormatter = DateFormatHelper(dateFormat)

  return `${dateFormatter.format(DateHelper(fulfillment.pickup_ready_date))} → ${fulfillment.delivery_date ? dateFormatter.format(DateHelper(fulfillment.delivery_date)) : 'No delivery date'}`
}

export function getFulfillmentsDateWindow(
  type: 'pickup' | 'delivery',
  fulfillments: ListFulfillment[] | FulfillmentDetails[]
): [string | null, string | null] {
  const key: keyof ListFulfillment =
    type === 'pickup' ? 'pickup_ready_date' : 'delivery_date'

  const dates = uniq(
    fulfillments
      .map((fulfillment) => fulfillment[key])
      .filter((date) => !isEmpty(date))
      .sort(
        (a, b) =>
          new Date(defaultTo(a, '')).getTime() -
          new Date(defaultTo(b, '')).getTime()
      )
  )

  if (dates.length === 0) {
    return [null, null]
  }

  if (dates.length === 1) {
    return [dates[0], null]
  }

  return [dates[0], dates[dates.length - 1]]
}

export function formatDateWindows(
  firstDate?: string | null,
  lastDate?: string | null
) {
  if (!firstDate && !lastDate) {
    return '-'
  }

  const formattedFirstDate = firstDate ? formatDate(firstDate) : ''
  const formattedLastDate = lastDate ? formatDate(lastDate) : ''

  if (
    !formattedFirstDate ||
    !formattedLastDate ||
    formattedFirstDate === formattedLastDate
  ) {
    return formattedFirstDate || formattedLastDate
  }

  return `${formattedFirstDate} to ${formattedLastDate}`
}

export function formatFulfillmentsDateWindows(
  fulfillments: ListFulfillment[] | FulfillmentDetails[] = []
) {
  if (fulfillments.length === 0) {
    return ['-', '-']
  }
  const [firstPickupDate, lastPickupDate] = getFulfillmentsDateWindow(
    'pickup',
    fulfillments
  )
  const [firstDeliveryDate, lastDeliveryDate] = getFulfillmentsDateWindow(
    'delivery',
    fulfillments
  )

  return [
    formatDateWindows(firstPickupDate, lastPickupDate),
    formatDateWindows(firstDeliveryDate, lastDeliveryDate),
  ]
}

export function mapFulfillmentStopsToTruckMileageStops(
  fulfillment?: Fulfillment
): TruckMileageStop[] {
  const pickup = fulfillment?.pickup_facility
  const delivery = fulfillment?.delivery_facility

  if (
    [
      pickup?.latitude,
      pickup?.longitude,
      delivery?.latitude,
      delivery?.longitude,
    ].every((item) => !isEmpty(item))
  ) {
    return [
      {
        latitude: Number(pickup?.latitude),
        longitude: Number(pickup?.longitude),
      },
      {
        latitude: Number(delivery?.latitude),
        longitude: Number(delivery?.longitude),
      },
    ]
  }

  if ([pickup?.zipcode, pickup?.zipcode].every((item) => !isEmpty(item))) {
    return [
      {
        postal_code: pickup?.zipcode,
      },
      {
        postal_code: delivery?.zipcode,
      },
    ]
  }

  return []
}

export function formatFulfillmentSource(fulfillment: Fulfillment) {
  return `Created by ${fulfillment.created_by_name} at ${formatDate(fulfillment.created_at, 'MM/DD/YYYY h:mm A')}`
}

export function convertCelsiusToFahrenheit(
  temperatureInCelsius: number | string | null | undefined
) {
  const convertedTemperatureInCelsius = Number(temperatureInCelsius)

  if (
    temperatureInCelsius == null ||
    (typeof temperatureInCelsius === 'string' &&
      isEmpty(temperatureInCelsius)) ||
    Number.isNaN(convertedTemperatureInCelsius)
  ) {
    return undefined
  }

  return (convertedTemperatureInCelsius * 9) / 5 + 32
}

/**
 * @param fulfillments Array of Fulfillment Details
 * @returns The lowest Max Temperature among the Order Items
 *  linked to the Fulfillments' Handling Units
 */
export function getFulfillmentsLowestMaxTemperature(
  fulfillments: FulfillmentDetails | FulfillmentDetails[]
) {
  const handlingUnits = (
    Array.isArray(fulfillments) ? fulfillments : [fulfillments]
  )
    .map((fulfillment) => fulfillment.handling_units)
    .flat()

  const requirements = handlingUnits.map(
    (handlingUnit) => handlingUnit.temperatureReqs
  )

  const temperatures = requirements
    .filter(
      (item) =>
        !isEmpty(item.max_temperature) &&
        !isEmpty(item.max_temperature_uom) &&
        !Number.isNaN(Number(item.max_temperature))
    )
    .map((item) => {
      if (item.max_temperature_uom === 'fahrenheit') {
        return Number(item.max_temperature)
      }

      return convertCelsiusToFahrenheit(item.max_temperature)
    })
    .filter((temperature) => temperature != null)

  const lowestTemperature = Math.min(...temperatures)

  if (lowestTemperature === undefined) {
    return {
      max_temperature_uom: '',
      max_temperature: '',
    }
  }

  return {
    max_temperature_uom: 'fahrenheit',
    max_temperature: String(lowestTemperature),
  }
}

/**
 * @param fulfillments Array of Fulfillment Details
 * @returns Formatted lowest Max Temperature among handling units
 */
export function formatFulfillmentsLowestMaxTemperature(
  fulfillments: FulfillmentDetails | FulfillmentDetails[]
) {
  const temperatureReqs = getFulfillmentsLowestMaxTemperature(fulfillments)

  if (isEmpty(temperatureReqs.max_temperature)) {
    return ''
  }

  return `${numberFormatter(temperatureReqs.max_temperature)}${
    temperatureReqs.max_temperature_uom === 'celsius' ? ' °C' : ' °F'
  }`
}

/**
 * @param contact Pickup or delivery contact
 * @returns An array where each line contains a formatted component of the contact (name, phone, email). Blank components are removed.
 */
export function formatFulfillmentContact(
  contact?: Fulfillment['pickup_contact']
) {
  if (!contact) {
    return ['-']
  }

  let phoneNumber = formatPhoneNumber(contact.phone)

  if (contact.extension) {
    phoneNumber += ` EXT ${contact.extension}`
  }

  const lines: string[] = [contact.name, phoneNumber, contact.email]

  return lines.filter((line) => !isEmpty(line))
}

/**
 * @param fulfillment Fulfillment details or list item
 * @returns Formatted weight. Returns - if total weight is null-ish or NaN.
 */
export function getFulfillmentWeight(fulfillment?: FulfillmentTypes) {
  const handlingUnits = defaultTo(fulfillment?.handling_units, [])
  const weight = handlingUnits.reduce(
    (sum, handlingUnit) =>
      sum + Number(getFulfillmentHandlingUnitShippedWeight(handlingUnit)),
    0
  )

  if (Number.isNaN(Number(weight))) {
    return null
  }

  return weight
}

/**
 * @param fulfillment Array of Fulfillment list items
 * @returns Total weight of the Fulfillments
 */
export function getFulfillmentsWeight(
  fulfillments?: ListFulfillment[] | FulfillmentDetails[]
) {
  return defaultTo(fulfillments, [])
    .map((fulfillment) => Number(getFulfillmentWeight(fulfillment)))
    .reduce((sum, weight) => sum + weight, 0)
}

/**
 * @param fulfillment Fulfillment details or list item
 * @returns Formatted weight. Returns - if total weight is null-ish or NaN.
 */
export function formatFulfillmentWeight(fulfillment?: FulfillmentTypes) {
  const weight = getFulfillmentWeight(fulfillment)

  return weight == null ? '-' : formatWeight(weight)
}

/**
 * @param fulfillments Array of Fulfillment Details
 * @returns The total volume, calculated based on the order items dimensions
 */
export function getFulfillmentsVolume(fulfillments: FulfillmentDetails[] = []) {
  return fulfillments.reduce((sum, fulfillment) => {
    return (
      sum +
      fulfillment.handling_units.reduce((unitSum, handlingUnit) => {
        return unitSum + calculateTotalVolume(handlingUnit.order_items)
      }, 0)
    )
  }, 0)
}

/**
 * @param fulfillment Fulfillment details or list item
 * @returns Formatted sum of HUs' package counts. Returns - if sum is NaN.
 */
export function formatFulfillmentTotalPieceCount(
  fulfillment?: FulfillmentTypes
) {
  const handlingUnits = defaultTo(fulfillment?.handling_units, [])
  const pieceCount = handlingUnits.reduce(
    (sum, handlingUnit) => sum + Number(handlingUnit.package_count),
    0
  )

  if (Number.isNaN(pieceCount)) {
    return '-'
  }

  return `${pieceCount} pieces`
}

/**
 * @param packageCount Count
 * @param packageType Type
 * @returns Formatted packaging info using the default labels for PackageType
 */
export function formatFulfillmentHandlingUnitPackaging(
  packageCount: FulfillmentHandlingUnit['package_count'],
  packageType: FulfillmentHandlingUnit['package_type']
) {
  let formattedPackageType = ''
  const SHORT_PACKAGE_TYPE_LABELS = new Map<PackageType, string>(
    PACKAGE_TYPE_LABELS
  )
  SHORT_PACKAGE_TYPE_LABELS.set('std_pallets', 'Pallets')

  if (packageType) {
    const shortPackageType = SHORT_PACKAGE_TYPE_LABELS.get(packageType)
    if (shortPackageType !== undefined) {
      formattedPackageType = shortPackageType
    }
  } else {
    formattedPackageType = plural('Pallet', +packageCount)
  }

  return `${packageCount} ${formattedPackageType.toLocaleLowerCase()}`
}

/**
 * @param fulfillments Array of Fulfillment details or list item
 * @returns The sum of handling unit's package count
 */
export function getFulfillmentsTotalPieceCount(
  fulfillments: FulfillmentTypes[] = []
) {
  return fulfillments.reduce((sum, fulfillment) => {
    return (
      sum +
      defaultTo(fulfillment.handling_units, []).reduce(
        (itemSum, handlingUnit) => {
          return itemSum + Number(handlingUnit.package_count)
        },
        0
      )
    )
  }, 0)
}

/**
 * @param fulfillments Array of Fulfillment details or list item
 * @returns The formatted sum of handling unit's package count
 */
export function formatFulfillmentsTotalPieceCount(
  fulfillments: FulfillmentTypes[] = []
) {
  return `${getFulfillmentsTotalPieceCount(fulfillments)} H/U`
}

/**
 * @param handlingUnit Fulfillment Handling Unit
 * @returns The summary/title of the handling unit, using its packaging info and its items' commodities
 */
export function formatFulfillmentHandlingUnitTitle(
  handlingUnit:
    | FulfillmentHandlingUnit
    | ListFulfillmentHandlingUnit
    | OrderFulfillmentsListItemHandlingUnit
) {
  const handlingUnitPackaging = formatFulfillmentHandlingUnitPackaging(
    handlingUnit.package_count,
    handlingUnit.package_type
  )
  const commodities = handlingUnit.order_items
    .map((item) => item.commodity)
    .filter((commodity) => !isEmpty(commodity))

  if (!commodities.length) {
    return handlingUnitPackaging
  }

  if (commodities.length === 1) {
    return `${handlingUnitPackaging} of ${commodities[0]}`
  }

  return `${handlingUnitPackaging} of ${commodities[0]}+${handlingUnit.order_items.length - 1}`
}

/**
 * Formats the temperature requirements for a enriched Fulfillment Handling Unit
 * @param item FulfillmentDetailsHandlingUnit
 * @returns If there is non-empty value for the max_temperature field, it returns the formatted
 * temperature with the UOM. Otherwise, it returns an empty string
 */
export function formatFulfillmentHandlingUnitMaxTemperature({
  temperatureReqs,
}: FulfillmentDetailsHandlingUnit) {
  if (isEmpty(temperatureReqs.max_temperature)) {
    return ''
  }

  return `${numberFormatter(temperatureReqs.max_temperature)}${
    temperatureReqs.max_temperature_uom === 'celsius' ? ' °C' : ' °F'
  }`
}

/**
 * @param params Commodity, ordered count, shipped count, package type, and custom package type
 * @returns A formatted string that expresses how much of the ordered quantity is being fulfilled
 */
export function formatFulfilledCommodity({
  commodity,
  customPackageType,
  orderedCount,
  packageType,
  shippedCount,
}: {
  commodity: OrderItem['commodity']
  customPackageType?: OrderItem['custom_package_type']
  orderedCount: number
  packageType: OrderItem['package_type']
  shippedCount: number
}) {
  const formattedPackageType = getFormattedPackageType(
    packageType,
    customPackageType,
    shippedCount
  )

  if (isEmpty(commodity)) {
    return `${shippedCount} of ${orderedCount} ${formattedPackageType.toLocaleLowerCase()}`
  }

  return `${shippedCount} of ${orderedCount} ${formattedPackageType.toLocaleLowerCase()} of ${commodity}`
}

export function formatHandlingUnitPONumbers(
  handlingUnit: FulfillmentDetailsHandlingUnit
) {
  return defaultEmptyString(
    handlingUnit.poNumbers.toSorted((a, b) => a.localeCompare(b)).join(', ')
  )
}

export function formatHandlingUnitSONumbers(
  handlingUnit: FulfillmentDetailsHandlingUnit
) {
  return defaultEmptyString(
    handlingUnit.soNumbers.toSorted((a, b) => a.localeCompare(b)).join(', ')
  )
}

export function formatHandlingUnitTotalShippedWeight(
  handlingUnit: FulfillmentDetailsHandlingUnit
) {
  return defaultEmptyString(
    formatWeight(defaultTo(handlingUnit.totalShippedWeight, ''))
  )
}

/**
 * @param fulfillment Fulfillment details
 * @returns Sum of the package_count of all the handling units inside the fulfillment
 */
export function getFulfillmentHandlingUnitsTotalPackageCount(
  fulfillment?: Fulfillment
) {
  const handlingUnits = defaultTo(fulfillment?.handling_units, [])

  const count = handlingUnits.reduce(
    (sum, handlingUnit) =>
      sum + Number(defaultTo(handlingUnit.package_count, 0)),
    0
  )

  return count
}

/**
 * @param handlingUnit Fulfillment Handling Unit
 * @returns Array with one entry per unique order among the order items included in the handling unit, sorted by Primary Ref
 */
export function getFulfillmentHandlingUnitUniqueOrders(
  handlingUnit?: FulfillmentHandlingUnit
) {
  const orders = defaultTo(
    handlingUnit?.order_items?.map((item) => item.order),
    []
  )

  return uniqBy(orders, (order) => order.uuid).sort((a, b) =>
    a.primary_ref.localeCompare(b.primary_ref)
  )
}

/**
 * @param handlingUnit Any compatible Fulfillment Handling Unit type
 * @returns Sum of the order items' shipped weight; null, if no items
 */
export function getFulfillmentHandlingUnitShippedWeight(
  handlingUnit?: FulfillmentHandlingUnitTypes
) {
  const orderItems = handlingUnit?.order_items

  if (!orderItems?.length) {
    return null
  }

  const totalWeight = orderItems.reduce(
    (sum, item) => sum + Number(item.shipped_package_weight),
    0
  )

  return totalWeight
}

/**
 * @param handlingUnit Fulfillment Handling Unit
 * @returns Formatted shipped weight of the HU
 */
export function formatFulfillmentHandlingUnitShippedWeight(
  handlingUnit?:
    | FulfillmentHandlingUnit
    | ListFulfillmentHandlingUnit
    | OrderFulfillmentsListItemHandlingUnit
) {
  return formatWeight(
    defaultTo(getFulfillmentHandlingUnitShippedWeight(handlingUnit), '')
  )
}

export function formatFulfillmentFillRate(
  fulfillment?: OrderFulfillmentsListItem
) {
  if (fulfillment?.fill_rate == null) {
    return '-'
  }

  return `${fulfillment.fill_rate?.toFixed(0)}%`
}

/**
 * @param fulfillment Fulfillment details
 * @returns The list of Primary Refs of the linked Orders
 */
export function getFulfillmentLinkedOrdersPrimaryRefs(
  fulfillment: FulfillmentDetails
) {
  return fulfillment.handling_units.reduce<string[]>((acc, handlingUnit) => {
    return [...acc, ...handlingUnit.orders.map((order) => order.primary_ref)]
  }, [])
}

/**
 * @param fulfillment Array of Fulfillment details
 * @returns The unique list of Primary Refs of the linked Orders
 */
export function formatFulfillmentsLinkedOrders(
  fulfillments: FulfillmentDetails[]
) {
  return defaultEmptyString(
    uniq(
      fulfillments.reduce<string[]>((acc, fulfillment) => {
        return [...acc, ...getFulfillmentLinkedOrdersPrimaryRefs(fulfillment)]
      }, [])
    ).join(', ')
  )
}
