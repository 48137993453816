import { Field, Layout, SpinnerWheel, Tag } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash'

import { useSettings } from '_shared_/settings/useSettings'
import { NotSet } from 'components/NotSet'

import { getAccessorialTagName, getPriceItemTypeTagName } from '../utils'
import { useAccessorialsTagsByFormValues } from './useAccessorialsTagsByFormValues'

type AccessorialsTagsProps = {
  readonly isLoading?: boolean
  readonly accessorialsTags: string[]
}

function AccessorialsTags({
  isLoading,
  accessorialsTags,
}: AccessorialsTagsProps) {
  if (isLoading) {
    return (
      <Layout.Group align="center" justify="flex-start">
        <SpinnerWheel size="18px" aria-label="Loading" />
      </Layout.Group>
    )
  }

  if (isEmpty(accessorialsTags)) {
    return <NotSet />
  }

  return (
    <Layout.Group align="center" justify="flex-start" gap="spacing-2">
      {accessorialsTags.map((accessorialTag) => (
        <Tag key={accessorialTag} variant="neutral" size="small">
          {accessorialTag}&nbsp;
        </Tag>
      ))}
    </Layout.Group>
  )
}

export type AccessorialsSummaryProps = {
  readonly accessorials?: Accessorial[]
  readonly requestedAccessorials?: PriceItemType[]
}

export function AccessorialsSummary({
  accessorials,
  requestedAccessorials,
}: AccessorialsSummaryProps) {
  const {
    values: [priceItemTypesEnabled],
  } = useSettings(['flags.ENABLE_PRICE_ITEM_TYPES'])

  const parsed = priceItemTypesEnabled
    ? (requestedAccessorials?.map(getPriceItemTypeTagName) ?? [])
    : (accessorials?.map(getAccessorialTagName) ?? [])

  return <AccessorialsTags accessorialsTags={parsed.flat()} />
}

export type AccessorialsSummaryFormFieldProps = {
  readonly mode: TransportationModeCode
  readonly usage: AccountingObjectType
  readonly values: {
    requested_accessorials: string[]
    accessorials: string[]
  }
}

export function AccessorialsSummaryFormField({
  mode,
  usage,
  values,
}: AccessorialsSummaryFormFieldProps) {
  const { isLoading, accessorialsTags } = useAccessorialsTagsByFormValues({
    mode,
    usage,
    values,
  })

  return (
    <Field data-testid="accessorials-summary">
      <Field.Label size="small">Accessorials&nbsp;</Field.Label>
      <AccessorialsTags
        isLoading={isLoading}
        accessorialsTags={accessorialsTags}
      />
    </Field>
  )
}
