import { SideNavigation, Tag } from '@loadsmart/loadsmart-ui'
import { Icon } from '@loadsmart/miranda-react'
import { useHistory } from 'react-router-dom'

import { useSettings } from '_shared_/settings/useSettings'
import { userUtils } from '_shared_/user'
import { appRoutes } from 'router/app-routes'
import { ANALYTICS_PORTAL_URL, USER_TYPE } from 'utils/constants'
import { getAppEnv } from 'utils/envVars'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const AnalyticsMenu = () => {
  const history = useHistory()

  const {
    values: [
      enableInsightsAI,
      enableSubpageAnalyticsPriceMonitor,
      enableSubpageAnalyticsReports,
    ],
  } = useSettings([
    'flags.ENABLE_FREIGHT_INSIGHTS_AI',
    'flags.ENABLE_SUBPAGE_ANALYTICS_PRICE_MONITOR',
    'flags.ENABLE_SUBPAGE_ANALYTICS_REPORTS',
  ])

  const env = getAppEnv().toLowerCase()

  const analyticsPortalURL =
    env === 'sandbox'
      ? `${ANALYTICS_PORTAL_URL}`
      : `${ANALYTICS_PORTAL_URL}/login`

  if (userUtils.isUserType([USER_TYPE.SG])) {
    return (
      <SideNavigation.Menu label="Analytics">
        {enableInsightsAI && (
          <SideNavigation.Menu.Item
            active={isActivePath(appRoutes.insights)}
            label="FreightIntel AI"
            url={appRoutes.insights}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked('Insights AI', appRoutes.insights, history)
            }}
            trailing={
              <Tag size="small" variant="success">
                NEW
              </Tag>
            }
          />
        )}
        ;
        {enableSubpageAnalyticsPriceMonitor && (
          <SideNavigation.Menu.Item
            active={isActivePath(appRoutes.priceMonitor)}
            label="Price Monitor"
            url={appRoutes.priceMonitor}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked(
                'Price Monitor',
                appRoutes.priceMonitor,
                history
              )
            }}
          />
        )}
        {enableSubpageAnalyticsReports && (
          <SideNavigation.Menu.Item
            active={isActivePath(appRoutes.shipperReports)}
            label="Reports"
            url={appRoutes.shipperReports}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked('Reports', appRoutes.shipperReports, history)
            }}
          />
        )}
        <SideNavigation.Menu.Item
          label="Analytics Portal"
          url="#"
          onClick={(event) => {
            event.preventDefault()
            window.open(analyticsPortalURL, '_blank')
          }}
          trailing={
            <Icon name="arrow-corner-up" color="color-text-placeholder" />
          }
        />
      </SideNavigation.Menu>
    )
  }

  return null
}
