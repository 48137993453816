import { isFunction } from '@loadsmart/utils-function'
import { get, set, isEmpty, isObject, toPath } from 'lodash'

import generateId from 'utils/generateId'

import toArray from './toArray'

export const METADATA_KEY = '_metadata'

export type TransientMetadata<T> = {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | Partial<Record<keyof T, unknown>>
  id: string
  errors: Partial<Record<keyof T, string>>
}

/**
 * This type includes additional `_metadata` to be used only in forms,
 * so we don't mix business-related properties with form-related ones.
 */
export type Transient<T = { [key: string]: unknown }> = T & {
  [METADATA_KEY]: TransientMetadata<T>
}

export type TransientDeep<T> = {
  [K in keyof T]: T[K] extends object
    ? T[K] extends Array<infer U>
      ? Array<TransientDeep<U>>
      : TransientDeep<T[K]>
    : T[K]
} & { _metadata: TransientMetadata<T> }

export function getMetadata<T>(obj: T, key?: string | string[]) {
  if (typeof key !== 'string' && !Array.isArray(key)) {
    return get(obj, METADATA_KEY)
  }

  return get(obj, [METADATA_KEY, ...toArray(key)])
}

/**
 * Creates a `_metadata` object in the provided `obj`, which includes a randomly generated
 * ID (using utils/generateId} - to be used in forms, list rendering, etc. - and an empty
 * `errors` object.
 * The idea is that we don't mix business-related properties with rendering-related or temporary ones.
 */
export function createTransient<T>(
  obj: T,
  _metadata?: Partial<TransientMetadata<T>>
): Transient<T> {
  return {
    ...obj,
    _metadata: {
      id: generateId(),
      errors: {},
      ..._metadata,
    },
  }
}
/**
 * @returns a new object with the same properties as the original object, but without the `_metadata` property.
 */
export const undoTransient = <T>(
  obj: TransientDeep<T> | Transient<T> | T
): T => {
  if (!isTransient(obj)) {
    return obj
  }

  return Object.keys(obj).reduce((acc, key) => {
    if (key === METADATA_KEY) {
      return acc
    }

    const value = obj[key as keyof T]

    if (Array.isArray(value)) {
      return {
        ...acc,
        [key]: value.map((item) => {
          if (isTransient(item)) {
            return undoTransient(item)
          }

          return item
        }),
      }
    }

    if (isTransient(value)) {
      return {
        ...acc,
        [key]: undoTransient(value),
      }
    }

    return {
      ...acc,
      [key]: value,
    }
  }, {} as T)
}

/**
 * Checks if the provided object is transient by verifying if it has an `_metadata` object
 * and if it has an `id` property.
 */
export function isTransient<T = unknown>(obj: T): obj is Transient<T> {
  if (isFunction(obj) || Array.isArray(obj) || !isObject(obj)) {
    return false
  }

  const metadata = getMetadata(obj)

  return (
    !isFunction(metadata) &&
    !Array.isArray(metadata) &&
    isObject(metadata) &&
    typeof get(metadata, 'id') === 'string'
  )
}

export function isArrayOfTransientObjects<T extends object>(
  obj: T | Array<T>
): obj is Array<Transient<T>> {
  return Array.isArray(obj) && obj.every(isTransient)
}

/**
 * Sets an error message in the `_metadata` object of the provided `obj` at the given `path`.
 * If the object is not transient or the path is empty, a clone of the original object is returned.
 */
export function setTransientError<T extends object>(
  obj: T,
  path: string,
  error: string
): T {
  // avoid mutating the original objectxw
  const clone = structuredClone(obj)

  if (!path || (!isTransient(obj) && !isArrayOfTransientObjects(obj))) {
    return clone
  }

  // a path like `items[0].weight`
  // after toPath: likelyMetadataPath = [ 'items', 0, 'weight' ]
  const likelyMetadataPath = toPath(path)
  // after splice:  likelyMetadataPath = [ 'items', 0], key = [ 'weight' ]
  const key = likelyMetadataPath.splice(-1)

  if (isTransient(get(clone, likelyMetadataPath))) {
    return set(
      clone,
      [...likelyMetadataPath, METADATA_KEY, 'errors', ...key],
      error
    )
  }

  return set(clone, [METADATA_KEY, 'errors', ...key], error)
}

/**
 * Gets an error message from the `_metadata` object of the provided `obj` at the given `key`.
 * If the object is not transient or the key is empty, `undefined` is returned.
 * @param obj The transient object
 * @param key The key to get the error from
 * @returns The error message or `undefined`
 * @example
 * const obj = createTransient({ attribute: 'value' })
 * setTransientError(obj, 'attribute', 'Required')
 * getTransientError(obj, 'attribute') // 'Required'
 * getTransientError(obj, 'items') // undefined
 * getTransientError(obj, '') // undefined
 * getTransientError({ attribute: 'value' }, 'attribute') // undefined
 * getTransientError(obj) // { attribute: 'Required' }
 */
export function getTransientError<T>(obj: Transient<T> | T, key?: string) {
  if (typeof key !== 'string') {
    return getMetadata(obj, 'errors')
  }

  return getMetadata(obj, ['errors', key])
}

/**
 * Checks if the provided transient object has any error in its `_metadata` object.
 * If the object is not transient, `false` is returned.
 * @param obj The transient object
 * @returns `true` if there is any error, `false` otherwise
 */
export function hasTransientError<T>(obj: Transient<T> | T): boolean {
  if (!obj || !isObject(obj)) {
    return false
  }

  if (!isEmpty(getTransientError(obj))) {
    return true
  }

  return Object.keys(obj).some((key) => {
    const value = obj[key as keyof T]

    if (Array.isArray(value) && !isEmpty(value) && isTransient(value[0])) {
      return value.some((item) => hasTransientError(item))
    }

    if (isTransient(value)) {
      return hasTransientError(value)
    }

    return false
  })
}

/**
 * Returns the number of errors stored in the provided transient object `_metadata`.
 * If the object is not transient, `0` is returned.
 * @param obj The transient object
 * @returns the number of errors in the `_metadata`
 */
export function getTransientErrorsCount<T>(obj: Transient<T> | T): number {
  if (!obj || !isObject(obj)) {
    return 0
  }

  if (isTransient(obj)) {
    return Object.keys(obj[METADATA_KEY].errors).length
  }

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key as keyof T]

    if (Array.isArray(value) && !isEmpty(value) && isTransient(value[0])) {
      return acc + getTransientErrorsCountFromArray(value)
    }

    if (isObject(value)) {
      return acc + getTransientErrorsCount(value)
    }

    return acc
  }, 0)
}

/**
 * Returns the number of errors stored in each transient object `_metadata` from the array.
 * If an object is not transient, `0` is returned.
 * @param objArray The array of transient objects
 * @returns the number of errors in the `_metadata` of all the objects
 */
export function getTransientErrorsCountFromArray<T>(
  objArray: Transient<T>[] | T[]
): number {
  return toArray(objArray).reduce(
    (count, obj) => count + getTransientErrorsCount(obj),
    0
  )
}

/**
 * Recursively resets the errors property on the _metadata
 * for the transient object and all of its possible nested transient objects
 */
export function clearTransientErrors<T extends object>(obj: T): T {
  if (!obj || !isObject(obj) || isEmpty(obj)) {
    return obj
  }

  const clonedObj: Transient<T> | T = structuredClone(obj)

  Object.keys(obj).forEach((key: string) => {
    const propertyValue = clonedObj[key as keyof T]

    if (Array.isArray(propertyValue)) {
      set(clonedObj, key, clearTransientArrayErrors(propertyValue))
    }

    if (isObject(propertyValue)) {
      set(clonedObj, key, clearTransientErrors(propertyValue))
    }
  })

  if (isTransient(clonedObj)) {
    set(clonedObj, [METADATA_KEY, 'errors'], {})
  }

  return clonedObj
}

/**
 * Recursively resets the errors property on the _metadata
 * for the transient object and all of its possible nested transient objects
 */
export function clearTransientArrayErrors<T extends object>(
  transientArray: Array<T>
): Array<T> {
  return transientArray.map((item) => clearTransientErrors(item))
}
