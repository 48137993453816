import { capitalize, defaultTo } from 'lodash'

import type {
  FulfillmentsPlan,
  FulfillmentsPlanStop,
} from './PlanFulfillmentsPage.types'

export function formatStop(
  stop?:
    | FulfillmentsPlanStop
    | {
        facilityName: string | null
        address: string | null
        city: string | null
        country: string | null
        state: string | null
        zipcode: string | null
        latitude: string | null
        longitude: string | null
      }
) {
  if (!stop) {
    return ''
  }

  return `${capitalize(defaultTo(stop.city, ''))}, ${stop.state} ${stop.zipcode}`
}

export function formatRoute(
  stops?:
    | FulfillmentsPlan['stops']
    | {
        facilityName: string | null
        address: string | null
        city: string | null
        country: string | null
        state: string | null
        zipcode: string | null
        latitude: string | null
        longitude: string | null
      }[]
) {
  if (!stops?.length) {
    return '-'
  }

  return stops.map((stop) => formatStop(stop)).join(' → ')
}
