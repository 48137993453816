import { IconClose } from '@loadsmart/icons'
import { Banner, LoadingDots, Select } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import { Button, Text, Layout } from '@loadsmart/miranda-react'
import type { FormikProps, FormikState } from 'formik'
import { useCallback, useEffect, useRef } from 'react'

import FormField from '_shared_/components/form/FormField'
import {
  CloseSidebarWrapper,
  FieldsContainer,
  Form,
  FormContainer,
  FieldSection,
  FieldSectionTitle,
  TextArea,
  Title,
  TitleContainer,
} from '_shared_/components/form/styles'
import { useSettings } from '_shared_/settings/useSettings'
import AliceCarriersAutocomplete from 'components/CarrierForm/CarrierFormV1/AliceCarriersAutocomplete'
import InputTag from 'components/Input/Tag'
import { theme } from 'styles/theme'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

import type { CarrierFormValue, SelectOption } from '../types'
import {
  objectToSelectedOptions,
  entityOptions,
  relationshipStatusOptions,
} from '../utils'
import { CarrierAutomationsCard } from './CarrierAutomationsCard'
import {
  FormActionsWrapper,
  InviteBar,
  FindNewCarriers,
  AliceSearchSubLabel,
} from './CarrierForm.styles'
import DeleteCarrierButton from './DeleteCarrierButton'
import LegacyAlternativeEmailsField from './LegacyAlternativeEmailsField'
import LegacyEmailField from './LegacyEmailField'

const FormTitle = ({ carrier }: { readonly carrier?: Carrier | null }) => (
  <Title data-testid="carrier-form_title">
    {!carrier && 'Add New '}Carrier
  </Title>
)

type Props = FormikProps<CarrierFormValue> & {
  readonly isLoading: boolean
  readonly hasErrorAliceCarrier: boolean
  readonly aliceCarrierSelectionCallback: (
    resetFn: (nextState?: Partial<FormikState<CarrierFormValue>>) => void,
    carrier: Carrier
  ) => Promise<void>
  readonly cancelCallback: () => void
  readonly carrier?: Carrier | null
  readonly closeSideBar: () => void
  readonly onModesChange: (modes: string[]) => void
  readonly modeOptions: Array<SelectOption>
  readonly equipmentTypeOptions: Array<SelectOption>
  readonly isLoadingEquipmentTypes: boolean
  readonly operationRegionOptions: Array<SelectOption>
}

type SelectableEvent = EventLike<Selectable | Selectable[] | null>

export default function CarrierForm({
  isLoading,
  hasErrorAliceCarrier,
  aliceCarrierSelectionCallback,
  cancelCallback,
  carrier,
  isSubmitting,
  isValid,
  resetForm,
  setTouched,
  setFieldValue,
  touched,
  dirty,
  values,
  closeSideBar,
  onModesChange,
  operationRegionOptions,
  modeOptions,
  equipmentTypeOptions,
  isLoadingEquipmentTypes,
}: Props) {
  const isFirstMount = useRef(true)

  const {
    values: [loadsmartDOT, maxNumberOfTags],
  } = useSettings([
    'settings.LOADSMART_DOT',
    'settings.MAX_NUMBER_OF_TAGS_PER_CARRIER',
  ])

  const isLoadsmartCarrier = values.dot === loadsmartDOT

  const handleFieldChange = useCallback(
    (field: string) => (value: any) => {
      setTouched({ ...touched, [field]: true })
      setFieldValue(field, value)
    },
    [setFieldValue, setTouched, touched]
  )

  const handleEntityChange = useCallback(
    (event: SelectableEvent) => {
      handleFieldChange('entity')(event.target.value)
    },
    [handleFieldChange]
  )

  const handleRelationshipStatusChange = useCallback(
    (event: SelectableEvent) => {
      handleFieldChange('relationship_status')(event.target.value)
    },
    [handleFieldChange]
  )

  const handleRegionChange = useCallback(
    (event: SelectableEvent) => {
      const chosenRegions = event.target.value

      handleFieldChange('operation_regions')(chosenRegions)
    },
    [handleFieldChange]
  )

  const handleModeChange = useCallback(
    (event: SelectableEvent) => {
      const chosenModes = event.target.value

      handleFieldChange('modes')(chosenModes)
    },
    [handleFieldChange]
  )

  const handleEquipmentChange = useCallback(
    (event: SelectableEvent) => {
      const chosenEquips = event.target.value

      handleFieldChange('equipment_types')(chosenEquips)
    },
    [handleFieldChange]
  )

  useEffect(() => {
    if (!carrier || !operationRegionOptions.length) {
      return
    }
    const carrierInitialRegions = objectToSelectedOptions(
      carrier.operation_regions,
      operationRegionOptions
    )

    handleFieldChange('operation_regions')(carrierInitialRegions)
    // We just need to run this once the options are loaded to set the initial carrier values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationRegionOptions])

  useEffect(() => {
    if (!carrier || !modeOptions.length) {
      return
    }
    const carrierInitialModes = objectToSelectedOptions(
      carrier.modes,
      modeOptions
    )

    handleFieldChange('modes')(carrierInitialModes)
    // We just need to run this once the options are loaded to set the initial carrier values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeOptions])

  useEffect(() => {
    if (!equipmentTypeOptions.length) {
      return
    }

    if (carrier?.equipment_types) {
      // Set the initial carrier values
      const carrierInitialEquipmentTypes = objectToSelectedOptions(
        carrier.equipment_types,
        equipmentTypeOptions
      )
      handleFieldChange('equipment_types')(carrierInitialEquipmentTypes)
    }

    // We just need to run this when the equipment options change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentTypeOptions])

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false
      return
    }
    const tags = values.tags || []
    const everyModeLabel = modeOptions.map((mode) => mode.label.toLowerCase())
    const selectedModesLabels = values.modes?.length
      ? values.modes.map((mode) => mode.label.toLowerCase())
      : []
    // Remove every mode label from tags
    const tagsWithoutModes = tags.filter((tag) => !everyModeLabel.includes(tag))
    // Include only the selected modes
    const newTags = [...selectedModesLabels, ...tagsWithoutModes]

    handleFieldChange('tags')(newTags)

    onModesChange(
      values.modes?.length ? values.modes.map((mode) => mode.value) : []
    )
    // We just need to run this effect when modes change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.modes])

  const getButtonText = () => {
    if (carrier) {
      return 'Save & Close'
    }

    return 'Add Carrier & Close'
  }

  return (
    <FormContainer background={theme.colors.whiteBroken} width="600px">
      <Form>
        <TitleContainer>
          <FormTitle carrier={carrier} />
          <CloseSidebarWrapper
            role="button"
            onClick={closeSideBar}
            aria-label="Close carrier panel"
          >
            <IconClose width={16} height={16} />
          </CloseSidebarWrapper>
        </TitleContainer>
        {isLoading ? (
          <Layout.Stack justify="center" align="center">
            <LoadingDots />
          </Layout.Stack>
        ) : (
          <>
            <FieldsContainer>
              {!carrier && (
                <FieldSection
                  gridSize={1}
                  gridTemplate="1fr"
                  data-testid="carrier-form_alice-search"
                >
                  <InviteBar>
                    <FindNewCarriers>
                      <AliceCarriersAutocomplete
                        onSelect={(selectedCarrier: Carrier) => {
                          aliceCarrierSelectionCallback(
                            resetForm,
                            selectedCarrier
                          )
                          analytics.track(
                            AnalyticsEvent.AddNewCarrierSearchOptionSelected,
                            AnalyticsEventTrigger.select,
                            { carrier: selectedCarrier }
                          )
                        }}
                      />
                    </FindNewCarriers>
                  </InviteBar>
                  <AliceSearchSubLabel>
                    If you prefer, use it to autocomplete carrier information
                    below.
                  </AliceSearchSubLabel>
                  {hasErrorAliceCarrier && (
                    <Banner
                      variant="danger"
                      title="We could not find information about the carrier you selected."
                    />
                  )}
                </FieldSection>
              )}
              <FieldSection gridSize={6} gridTemplate="1fr 1fr 1fr 1fr 1fr 1fr">
                <FieldSectionTitle span={2} isLoading={isLoading}>
                  Carrier details
                </FieldSectionTitle>
                <FormField
                  name="name"
                  label="Carrier name"
                  disabled={isSubmitting || isLoadsmartCarrier}
                  maxLength={65}
                  span={6}
                  required
                  data-testid="carrier-form_field_name"
                />
                <FormField
                  name="dot"
                  label="dot"
                  maxLength={10}
                  disabled={isLoadsmartCarrier}
                  required
                  data-testid="carrier-form_field_dot"
                  span={2}
                />
                <FormField
                  name="mc"
                  label="mc number"
                  maxLength={10}
                  disabled={isLoadsmartCarrier}
                  data-testid="carrier-form_field_mc"
                  span={2}
                />
                <FormField
                  name="scac"
                  label="scac"
                  maxLength={10}
                  disabled={isLoadsmartCarrier}
                  data-testid="carrier-form_field_scac"
                  span={2}
                />

                <FormField
                  name="relationship_status"
                  label="relationship status"
                  data-testid="carrier-form_field_entity"
                  span={3}
                >
                  {({ field }) => (
                    <Select
                      options={relationshipStatusOptions as GenericOption[]}
                      onChange={handleRelationshipStatusChange}
                      value={values.relationship_status as Selectable}
                      name={field.name}
                      id={field.name}
                    />
                  )}
                </FormField>
                <FormField
                  name="entity"
                  label="type"
                  data-testid="carrier-form_field_entity"
                  span={3}
                >
                  {({ field }) => (
                    <Select
                      name={field.name}
                      id={field.name}
                      onChange={handleEntityChange}
                      options={entityOptions}
                      value={values.entity as Selectable}
                      disabled={isLoadsmartCarrier}
                    />
                  )}
                </FormField>

                <LegacyEmailField
                  label="Primary email"
                  maxLength={50}
                  data-testid="carrier-form_field_email"
                />
                <LegacyAlternativeEmailsField />

                <FormField
                  name="cargo_on_file"
                  label="Cargo insurance"
                  span={3}
                  disabled={isLoadsmartCarrier}
                  data-testid="carrier-form_field_cargo_on_file"
                />
                <FormField
                  name="bipd_on_file"
                  label="BIPD insurance"
                  span={3}
                  disabled={isLoadsmartCarrier}
                  data-testid="carrier-form_field_bipd_on_file"
                />
                <FormField
                  name="regions"
                  label="region of operation"
                  tooltip="Regions where the carriers provide they services"
                  data-testid="carrier-form_field_regions"
                  span={3}
                >
                  {({ field }) => (
                    <Select
                      multiple
                      name={field.name}
                      id={field.name}
                      placeholder="Select your regions of operation"
                      onChange={handleRegionChange}
                      options={operationRegionOptions}
                      value={values.operation_regions as Selectable[]}
                      disabled={isLoadsmartCarrier}
                    />
                  )}
                </FormField>

                <FormField
                  name="mode"
                  label="mode"
                  data-testid="carrier-form_field_mode"
                  span={3}
                >
                  {({ field }) => (
                    <Select
                      multiple
                      name={field.name}
                      id={field.name}
                      placeholder="Select the modes"
                      onChange={handleModeChange}
                      options={modeOptions}
                      value={values.modes as Selectable[]}
                      disabled={isLoadsmartCarrier}
                    />
                  )}
                </FormField>

                <FormField
                  name="equipment"
                  label="equipment"
                  tooltip="Type of vehicle that the carrier offers for transport a shipment"
                  data-testid="carrier-form_field_equipment"
                  span={6}
                >
                  {({ field }) => (
                    <Select
                      multiple
                      name={field.name}
                      id={field.name}
                      placeholder="Select the equipments"
                      onChange={handleEquipmentChange}
                      options={equipmentTypeOptions}
                      value={values.equipment_types as Selectable[]}
                      disabled={isLoadsmartCarrier || isLoadingEquipmentTypes}
                    />
                  )}
                </FormField>
              </FieldSection>
              <FieldSection>
                <FieldSectionTitle>More Info</FieldSectionTitle>
                <FormField
                  name="phone"
                  label="phone"
                  maxLength={12}
                  disabled={isLoadsmartCarrier}
                  span={2}
                  data-testid="carrier-form_field_phone"
                />
                <FormField
                  name="hq_address"
                  label="hq address"
                  maxLength={50}
                  disabled={isLoadsmartCarrier}
                  span={2}
                  data-testid="carrier-form_field_hq_address"
                />
                <FormField
                  name="req_safety_passed"
                  label="safety requirements"
                  maxLength={50}
                  disabled={isLoadsmartCarrier}
                  component={TextArea}
                  span={2}
                  data-testid="carrier-form_field_req_safety_passed"
                />
                <FormField
                  name="notes"
                  label="notes"
                  component={TextArea}
                  span={2}
                  data-testid="carrier-form_field_notes"
                />
              </FieldSection>
              <FieldSection gridTemplate="1fr">
                <FieldSectionTitle>Tag Carrier</FieldSectionTitle>
                <Text variant="body-sm">
                  Use this to group your carriers and help you invite the right
                  carriers to bid.
                </Text>
                <FormField
                  name="tags"
                  label={`add up to ${maxNumberOfTags} TAGs`}
                  data-testid="tags-field"
                >
                  {({ field }) => (
                    <InputTag
                      {...field}
                      values={values.tags || []}
                      onChangeTags={(value: Array<string>) => {
                        handleFieldChange('tags')(value)
                        analytics.track(AnalyticsEvent.AddNewTagToCarrier)
                      }}
                      maxNumberOfTags={maxNumberOfTags}
                    />
                  )}
                </FormField>
              </FieldSection>
              {!isLoadsmartCarrier && (
                <CarrierAutomationsCard
                  autoAcceptTender={Boolean(values.auto_accept_tender)}
                  onChange={(field, value) => {
                    handleFieldChange(field)(value)
                  }}
                />
              )}
            </FieldsContainer>
            <FormActionsWrapper width="600px">
              <Layout.Group>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={[!isValid, !dirty, isSubmitting].some(
                    (value) => Boolean(value) === true
                  )}
                  loading={isSubmitting}
                  data-testid="carrier-form_submit-button"
                >
                  {getButtonText()}
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={cancelCallback}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Layout.Group>
              {carrier && !isLoadsmartCarrier && (
                <DeleteCarrierButton
                  carrier={carrier}
                  cancelCallback={cancelCallback}
                  closeSideBar={closeSideBar}
                  disabled={isSubmitting}
                />
              )}
            </FormActionsWrapper>
          </>
        )}
      </Form>
    </FormContainer>
  )
}
