import { Layout, Link, Text } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import {
  formatFulfillmentHandlingUnitMaxTemperature,
  formatFulfillmentHandlingUnitTitle,
  formatHandlingUnitPONumbers,
  formatHandlingUnitSONumbers,
  formatHandlingUnitTotalShippedWeight,
} from 'fulfillments/fulfillment-utils'
import { isEmpty } from 'lodash'
import { Fragment } from 'react'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import { appRoutes } from 'router/app-routes'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'
import type { FulfillmentDetailsHandlingUnit } from '../ViewFulfillmentPage.data'

const HandlingUnitCardWrapper = styled(Layout.Box)`
  background: ${toCSSValue('color-background-secondary')};
  border: 1px solid ${toCSSValue('color-border-input')};
  max-width: 250px;
`

export function FulfillmentHandlingUnitsGridCard({
  handlingUnit,
}: Readonly<{ handlingUnit?: FulfillmentDetailsHandlingUnit }>) {
  if (!handlingUnit) {
    return null
  }

  const formattedTemperatureReq =
    formatFulfillmentHandlingUnitMaxTemperature(handlingUnit)

  return (
    <HandlingUnitCardWrapper data-testid="fulfillment-HU-card">
      <Layout.Stack gap="spacing-1">
        <Text variant="body-md-bold">
          {formatFulfillmentHandlingUnitTitle(handlingUnit)}
        </Text>

        <Layout.Group align="center" gap="spacing-2">
          <Text color="color-text-tertiary">Order</Text>
          <Text>
            {handlingUnit.orders?.map((order, index) => (
              <Fragment key={order.uuid}>
                {!!index && <>,</>}
                <Link
                  target="_blank"
                  href={generatePath(appRoutes.orderDetails, {
                    orderId: order.uuid,
                  })}
                >
                  {order.primary_ref}
                </Link>
              </Fragment>
            ))}
          </Text>
        </Layout.Group>

        <Layout.Group align="center" gap="spacing-2">
          <Text color="color-text-tertiary">PO</Text>
          <Text>{formatHandlingUnitPONumbers(handlingUnit)}</Text>
        </Layout.Group>

        <Layout.Group align="center" gap="spacing-2">
          <Text color="color-text-tertiary">SO</Text>
          <Text>{formatHandlingUnitSONumbers(handlingUnit)}</Text>
        </Layout.Group>

        <Layout.Group align="center" gap="spacing-0-5">
          <Text variant="body-sm">
            {formatHandlingUnitTotalShippedWeight(handlingUnit)}
          </Text>
          {!isEmpty(formattedTemperatureReq) && (
            <>
              <Text variant="body-sm">&bull;</Text>
              <Text variant="body-sm">{formattedTemperatureReq}</Text>
            </>
          )}
        </Layout.Group>
      </Layout.Stack>
    </HandlingUnitCardWrapper>
  )
}

export function FulfillmentHandlingUnitsGrid() {
  const { fulfillment } = useViewFulfillmentContext()

  if (!fulfillment || !fulfillment.handling_units?.length) {
    return null
  }

  return (
    <Layout.Grid minColumnWidth="200px">
      {fulfillment.handling_units.map((handlingUnit) => (
        <FulfillmentHandlingUnitsGridCard
          key={handlingUnit.uuid}
          handlingUnit={handlingUnit}
        />
      ))}
    </Layout.Grid>
  )
}
