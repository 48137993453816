import { Divider, Layout } from '@loadsmart/miranda-react'
import { FulfillmentFacilityAddress } from 'fulfillments/components/FulfillmentFacilityAddress'
import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'
import { PlanFulfillmentsPlanPreviewStopCardHUs } from './PlanFulfillmentsPlanPreviewStopCardHUs'

export default function PlanFulfillmentsPlanPreviewStopCardDelivery({
  stopFulfillments,
}: Readonly<{
  stopFulfillments: FulfillmentDetails[]
}>) {
  const { plan } = usePlanFulfillmentsContext()

  return (
    <Layout.Stack gap="spacing-8" padding="none">
      <FulfillmentFacilityAddress
        addressLineProps={{ variant: 'body-md' }}
        fulfillment={stopFulfillments[0]}
        nameLineProps={{ variant: 'heading-sm-bold' }}
        stop="delivery"
      />

      <Divider />

      <Layout.Grid>
        {stopFulfillments.map((fulfillment) => (
          <PlanFulfillmentsPlanPreviewStopCardHUs
            key={fulfillment.uuid}
            fulfillment={fulfillment}
            pickupStop={plan?.stopsMap[fulfillment.pickup_facility.uuid]}
            showFulfillment
          />
        ))}
      </Layout.Grid>
    </Layout.Stack>
  )
}
