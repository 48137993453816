import { Banner, Button, Layout } from '@loadsmart/miranda-react'
import { useFulfillmentFormContext } from 'fulfillments/components/FulfillmentFormContext'
import { FulfillmentInfo } from 'fulfillments/components/FulfillmentInfo'
import { FulfillmentLane } from 'fulfillments/components/FulfillmentLane'
import { FulfillmentNotes } from 'fulfillments/components/FulfillmentNotes'
import { getUserType } from 'fulfillments/create/CreateFulfillmentForm'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import PageIndex from 'components/PageIndex'
import { hasTransientError } from 'utils/transient'

const formIndexes = [{ label: 'Freight Info', id: 'fulfillment-freight-info' }]

export function EditFulfillmentForm() {
  const { user } = useCurrentUser()
  const { fulfillment } = useFulfillmentFormContext()
  const userType = getUserType(user?.shipper)
  const hasError = hasTransientError(fulfillment)

  return (
    <form>
      <Layout.Container>
        <Layout.Row columnGap="spacing-8">
          <Layout.Column offsetLg="3" sm="12" md="8" lg="6">
            <Layout.Stack gap="spacing-6">
              {hasError && (
                <Banner variant="danger">
                  <Banner.Title>
                    Please fix the errors below to proceed
                  </Banner.Title>
                </Banner>
              )}
              <FulfillmentInfo />
              <FulfillmentLane userType={userType} />
              <FulfillmentNotes />
              <Layout.Group justify="flex-end">
                <Button type="button" variant="primary" onClick={() => {}}>
                  Save fulfillment
                </Button>
              </Layout.Group>
            </Layout.Stack>
          </Layout.Column>
          <Layout.Column sm="12" md="4" lg="3">
            <PageIndex values={formIndexes} />
          </Layout.Column>
        </Layout.Row>
      </Layout.Container>
    </form>
  )
}
