import { defaultTo } from 'lodash'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'

import { useOffsetPagination } from 'hooks/usePagination'
import { SEARCH_TERM_CHANGE_DEBOUNCE_MS } from 'orders/constants'
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  getListOrdersParams,
  listOrders,
} from 'orders/order-service'

import { mapFilters } from './useOrdersFiltersQueryParamPlugin'
import { mapParams } from './useOrdersPaginationQueryParamsPlugin'
import { usePaginatedOrdersFilters } from './usePaginatedOrders.filters'

export function usePaginatedOrders(enabled: boolean = true) {
  const {
    clearFilters,
    setFilter,
    setFilters,
    values: filters,
  } = usePaginatedOrdersFilters()

  const filterParams = getListOrdersParams({
    ...mapParams(filters),
    ...mapFilters(filters),
  })

  const query = useQuery({
    enabled,
    queryKey: ['listOrders', filterParams],
    queryFn: ({ signal }) =>
      listOrders(
        {
          ...filterParams,
        },
        { signal }
      ),
    refetchOnWindowFocus: false,
  })

  const { getOffsetForPage, ...pagination } = useOffsetPagination({
    resultsCount: defaultTo(query.data?.count, 0),
    pageSize: defaultTo(filters.limit, DEFAULT_LIMIT),
    currentOffset: defaultTo(filters.offset, DEFAULT_OFFSET),
  })

  const onPageChange = useCallback(
    (event: { page: number; pageSize: number }) => {
      const { page, pageSize } = event

      setFilters({
        limit: pageSize,
        offset: getOffsetForPage(page),
      })
    },
    [getOffsetForPage, setFilters]
  )

  const onSearchChange = useDebouncedCallback((search: string) => {
    setFilters({
      search,
      offset: DEFAULT_OFFSET,
    })
  }, SEARCH_TERM_CHANGE_DEBOUNCE_MS)

  return {
    ...query,
    ...pagination,
    clearFilters,
    filters,
    getOffsetForPage,
    onPageChange,
    onSearchChange,
    setFilter,
    setFilters,
  }
}
