import { Layout, Text } from '@loadsmart/miranda-react'
import {
  formatFulfillmentsDateWindows,
  formatFulfillmentsLowestMaxTemperature,
  formatFulfillmentsTotalPieceCount,
} from 'fulfillments/fulfillment-utils'

import { TextList } from '_shared_/components/TextList'
import { formatVolume, formatWeight } from 'components/ShippingItemsManager'
import { plural } from 'utils/strings'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'
import { formatRoute } from '../PlanFulfillmentsPage.utils'

export default function PlanFulfillmentsPlanPreviewHeader() {
  const {
    plan,
    selectedFulfillments,
    selectedFulfillmentsUUIDs,
    truckMileage,
  } = usePlanFulfillmentsContext()
  const fulfillmentsCount = selectedFulfillmentsUUIDs.length

  if (!plan) {
    return null
  }

  const { stops, totalVolume, totalWeight } = plan

  const [pickupDates, deliveryDates] =
    formatFulfillmentsDateWindows(selectedFulfillments)

  const maxTemperature =
    formatFulfillmentsLowestMaxTemperature(selectedFulfillments)

  const totalMileage = truckMileage?.total_miles

  return (
    <Layout.Stack gap="spacing-3" data-testid="preview-header">
      <Layout.Box padding="none">
        <Text variant="heading-md-bold">{formatRoute(stops)}</Text>
      </Layout.Box>

      <TextList
        separator={<>&bull;</>}
        separatorProps={{
          color: 'color-text-secondary-inverted',
          variant: 'body-md',
        }}
        values={[
          `${pickupDates} → ${deliveryDates}`,
          totalMileage ? `${totalMileage} miles` : null,
        ]}
        valueProps={{ color: 'color-text-tertiary', variant: 'body-md' }}
      />

      <TextList
        separator={<>&bull;</>}
        separatorProps={{
          color: 'color-text-secondary-inverted',
          variant: 'body-md',
        }}
        values={[
          `${fulfillmentsCount} ${plural('fulfillment', fulfillmentsCount)}`,
          formatFulfillmentsTotalPieceCount(selectedFulfillments),
          totalWeight ? formatWeight(totalWeight) : null,
          totalVolume ? formatVolume(totalVolume) : null,
          maxTemperature,
        ]}
        valueProps={{ color: 'color-text-tertiary', variant: 'body-md' }}
      />
    </Layout.Stack>
  )
}
