import { yupResolver } from '@hookform/resolvers/yup'
import { Spinner } from '@loadsmart/loadsmart-ui'
import { Button, Layout } from '@loadsmart/miranda-react'
import * as Sentry from '@sentry/react'
import { isAxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import type { QueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useSettings } from '_shared_/settings/useSettings'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { usePriceItemTypes, useShipperSettings } from 'hooks/useQuery'
import { useShipperMember } from 'hooks/useShipperMembers'
import { useExtraChargeTypes } from 'rfp/hooks/proposals'
import {
  useRfpAwardAcceptanceDeadlines,
  useRfpBidTypes,
  useRfpCurrencies,
  useRfpModes,
  useRfpRateTypes,
  useRfpTimePeriods,
  useDistanceTypes,
} from 'rfp/hooks/rfp'
import type {
  RequestForProposal,
  RequestForProposalDetail,
  ExtraChargeType,
} from 'rfp/rfp.types'
import { create as createRFP, partialUpdate } from 'rfp/services/rfp'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { MAX_FILE_SIZE_MB, RFP_STATE } from 'utils/constants'
import {
  DEFAULT_AWARD_ACCEPTANCE_DEADLINE_DAYS,
  getSelectedOption,
} from 'utils/rfp'
import { validateFileUpload } from 'utils/validateFiles'

import { ErrorBanner, NewFormHeader } from './componentUtils'
import { getDateWithUTCHours } from './date'
import RFPAccessorialsSection from './RFPFormDetails/AccessorialsField/accessorialsSection'
import RFPCarrierDeadlinesSection from './RFPFormDetails/carrierDeadlinesSection'
import RFPContactsSection from './RFPFormDetails/contactsSection'
import RFPContractSection from './RFPFormDetails/contractSection'
import RFPDetailsSection from './RFPFormDetails/detailsSection'
import DocumentsSection from './RFPFormDetails/documentsSection'
import type { FormValue } from './RFPFormDetails/types'
import type { RFPFormProps } from './types'
import {
  RFPFormSchema,
  getInitialPriceItemTypes,
  getInitialRequestedAccessorials,
  getInitialValues,
  getRawInitialValues,
  getTimeSplitted,
  mapFormValuesToApi,
} from './utils'

const handleCreateRfpError = (error: unknown, formData: FormData) => {
  const rfpThatFailed = {} as any

  formData.forEach((value, key) => (rfpThatFailed[key] = value))

  analytics.track(AnalyticsEvent.CreateRFP, AnalyticsEventTrigger.error, {
    createdAt: new Date().toISOString(),
    mode: rfpThatFailed.mode,
    currency: rfpThatFailed.currency,
    receiveRates: rfpThatFailed.rate_type,
    distanceUnity: rfpThatFailed.distance_type,
    volumeFrequency: rfpThatFailed.capacity_time_period,
    bidType: rfpThatFailed.bid_type,
    awardAcceptance: rfpThatFailed.award_acceptance_deadline,
    accessorials: !!rfpThatFailed.requested_accessorials_intermediary?.length,
    contact: !!rfpThatFailed.shipper_email,
    documents: !!rfpThatFailed.attachments?.length,
  })

  const handleToastError = (value: string | string[]) => {
    if (Array.isArray(value)) {
      value.forEach((errorMessage) => toast.error(errorMessage))
    } else {
      toast.error(value)
    }
  }

  if (isAxiosError(error)) {
    const { response } = error

    if (response?.data) {
      for (const [, value] of Object.entries(
        response.data as Record<string, string[] | string>
      )) {
        handleToastError(value)
      }
      return
    }
  }

  toast.error('Could not create a RFP, please contact an admin')
}

async function editRFP(
  rfp: RequestForProposalDetail,
  formValues: FormValue,
  priceItemTypesEnabled: boolean,
  setHasCreateOrEditError: (value: boolean) => void,
  successCallback: () => void,
  queryClient: QueryClient
) {
  const data: Partial<RequestForProposal> = {}

  const mappedValues = mapFormValuesToApi(formValues, priceItemTypesEnabled)
  data.request_deadline = mappedValues.request_deadline

  if (rfp.state === RFP_STATE.DRAFT) {
    data.name = mappedValues.name
    data.rate_type = mappedValues.rate_type
    data.mode = mappedValues.mode
    data.contract_start = mappedValues.contract_start
    data.contract_end = mappedValues.contract_end
    data.shipper_name = mappedValues.shipper_name
    data.shipper_email = mappedValues.shipper_email
    data.shipper_phone = mappedValues.shipper_phone
    data.save_contact_info = Boolean(mappedValues.save_contact_info)
    data.capacity_time_period = mappedValues.capacity_time_period
    data.currency = mappedValues.currency
    data.conversion_rate = mappedValues.conversion_rate
    data.bid_type = mappedValues.bid_type as BidType
    data.distance_type = mappedValues.distance_type
    data.award_acceptance_deadline = Number(
      mappedValues.award_acceptance_deadline
    )

    if (priceItemTypesEnabled) {
      data.requested_accessorials = mappedValues.requested_accessorials
      data.requested_accessorials_intermediary =
        mappedValues.requested_accessorials_intermediary
    } else {
      data.requested_extra_charges = mappedValues.requested_extra_charges
      data.requested_extra_charges_intermediary =
        mappedValues.requested_extra_charges_intermediary
    }
  }

  try {
    setHasCreateOrEditError(false)

    await partialUpdate({
      id: rfp.id,
      payload: data,
      attachments: {
        formAttachments: formValues.attachments,
        oldAttachments: rfp.attachments,
      },
      queryClient,
    })

    successCallback()
  } catch (error) {
    setHasCreateOrEditError(true)
    Sentry.captureException(error)
  }
}

async function submitRFP(
  formValues: FormValue,
  priceItemTypesEnabled: boolean,
  setHasCreateOrEditError: (value: boolean) => void
) {
  const formData = new FormData()

  const values = mapFormValuesToApi(formValues, priceItemTypesEnabled)

  formData.append('name', values.name)
  formData.append('rate_type', values.rate_type)
  formData.append('mode', values.mode)
  formData.append('state', values.state)
  formData.append('contract_start', values.contract_start)
  formData.append('contract_end', values.contract_end)
  formData.append('shipper_name', values.shipper_name)
  formData.append('shipper_email', values.shipper_email)
  formData.append('shipper_phone', values.shipper_phone)
  formData.append('currency', values.currency)
  formData.append('conversion_rate', values.conversion_rate)
  formData.append('bid_type', values.bid_type)
  formData.append('save_contact_info', values.save_contact_info)
  formData.append('request_deadline', values.request_deadline)
  formData.append('capacity_time_period', values.capacity_time_period)
  formData.append('award_acceptance_deadline', values.award_acceptance_deadline)
  formData.append('distance_type', values.distance_type)

  formValues.attachments.forEach((file: File) =>
    formData.append('attachments', file)
  )

  if (values.requested_extra_charges) {
    values.requested_extra_charges.forEach((extraChargeId) => {
      formData.append('requested_extra_charges', extraChargeId.toString())
    })
  }

  if (values.requested_extra_charges_intermediary) {
    formData.append(
      'requested_extra_charges_intermediary',
      JSON.stringify(values.requested_extra_charges_intermediary)
    )
  }

  if (values.requested_accessorials) {
    values.requested_accessorials.forEach((accessorialUuid) => {
      formData.append('requested_accessorials', accessorialUuid)
    })
  }

  if (values.requested_accessorials_intermediary) {
    formData.append(
      'requested_accessorials_intermediary',
      JSON.stringify(values.requested_accessorials_intermediary)
    )
  }

  try {
    setHasCreateOrEditError(false)
    const data = await createRFP(formData)

    analytics.track(AnalyticsEvent.CreateRFP, AnalyticsEventTrigger.success, {
      mode: data.mode,
      currency: data.currency,
      receiveRates: data.rate_type,
      distanceUnity: data.distance_type,
      volumeFrequency: data.capacity_time_period,
      bidType: data.bid_type,
      awardAcceptance: data.award_acceptance_deadline,
      accessorials: !!data.requested_accessorials_intermediary?.length,
      contact: !!data.shipper_email,
      documents: !!data.attachments?.length,
    })

    return data
  } catch (error) {
    setHasCreateOrEditError(true)
    Sentry.captureException(error)

    handleCreateRfpError(error, formData)

    return undefined
  }
}

const isSomeFieldLoading = (list: boolean[]) =>
  list.some((fieldStatus) => fieldStatus)

const emptyArray: unknown = []

const checkIsDisabledField = (state: string | undefined) => {
  if (!state) {
    return false
  }

  return (
    state === RFP_STATE.PUBLISHED ||
    state === RFP_STATE.CLOSED ||
    state === RFP_STATE.AWARDING
  )
}

const checkRenderNull = (state: string | undefined) =>
  state === RFP_STATE.ARCHIVED || state === RFP_STATE.FINALIZED

const RFPForm = ({ rfp }: RFPFormProps) => {
  const { user } = useCurrentUser()
  const history = useHistory()
  const {
    values: [priceItemTypesEnabled],
  } = useSettings(['flags.ENABLE_PRICE_ITEM_TYPES'])

  const [filteredBidTypeOptions, setFilteredBidTypeOptions] =
    useState<SelectOption[]>()

  const [hasCreateOrEditError, setHasCreateOrEditError] = useState(false)

  const { data: modeOptions, isLoading: modesAreLoading } = useRfpModes()
  const { data: rateTypes, isLoading: rateTypesAreLoading } = useRfpRateTypes()
  const { data: timePeriods, isLoading: timePeriodsAreLoading } =
    useRfpTimePeriods()
  const { data: currencies, isLoading: currenciesAreLoading } =
    useRfpCurrencies()
  const { data: rfpBidTypesOptions, isLoading: bidTypesAreLoading } =
    useRfpBidTypes()
  const {
    data: awardAcceptanceDeadlinesOptions,
    isLoading: acceptanceDeadlinesAreLoading,
  } = useRfpAwardAcceptanceDeadlines()
  const {
    data: extraChargeTypes = emptyArray as ExtraChargeType[],
    isLoading: extraChargesAreTypesAreLoading,
  } = useExtraChargeTypes({ enabled: !priceItemTypesEnabled })

  const { data: shipperSettings, isLoading: shipperSettingsAreLoading } =
    useShipperSettings()
  const { data: shipperData, isLoading: shipperDataIsLoading } =
    useShipperMember(user?.user_uuid ?? '')
  const { data: distanceTypes = [], isLoading: distanceTypesAreLoading } =
    useDistanceTypes()

  useEffect(
    () => setFilteredBidTypeOptions(rfpBidTypesOptions),
    [rfpBidTypesOptions]
  )

  const {
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitting },
    setError,
    clearErrors,
    getValues,
    trigger,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(RFPFormSchema),
    mode: 'all',
    // Even thought the "real" default values will be set in the useEffect below,
    // using the reset function, react-hook-form still needs defaultValues to be defined
    defaultValues: getRawInitialValues(),
  })

  const watchCurrency = watch('currency')
  const watchCapacityTimePeriod = watch('capacityTimePeriod')
  const watchBidType = watch('bidType')
  const watchAwardAcceptanceDeadline = watch('awardAcceptanceDeadline')
  const watchTime = watch('time')
  const watchBidsDeadline = watch('bidsDeadline')
  const watchContractStartDate = watch('contractStartDate')
  const watchContractEndDate = watch('contractEndDate')
  const watchAttachments = watch('attachments') as File[]
  const watchMode = watch('mode')
  const queryClient = useQueryClient()
  const {
    data: priceItemTypes = emptyArray as PriceItemType[],
    isLoading: priceItemTypesLoading,
  } = usePriceItemTypes(
    { modes: watchMode?.value, usage: 'rfp' },
    { enabled: !!priceItemTypesEnabled }
  )

  const isFormLoading = isSomeFieldLoading([
    modesAreLoading,
    rateTypesAreLoading,
    bidTypesAreLoading,
    acceptanceDeadlinesAreLoading,
    timePeriodsAreLoading,
    currenciesAreLoading,
    shipperDataIsLoading,
    extraChargesAreTypesAreLoading,
    shipperSettingsAreLoading,
    distanceTypesAreLoading,
    !priceItemTypes,
  ])

  const handleShipperFilesChange = useCallback(
    (files: File[]) => {
      const errorMessage = validateFileUpload(files)
      if (errorMessage) {
        setError('attachments', { type: 'required', message: errorMessage })
        return
      }
      clearErrors('attachments')
      setValue('attachments', files)
    },
    [clearErrors, setError, setValue]
  )

  useEffect(() => {
    function setInitialValuesWithBackendData() {
      return getInitialValues({
        awardAcceptanceDeadlinesOptions,
        currencies,
        extraChargeTypes,
        priceItemTypesEnabled,
        rateTypes,
        shipperData,
        shipperSettings,
        rfpBidTypesOptions,
        modeOptions: modeOptions as SelectOption[],
        timePeriods,
        rfp,
        distanceOptions: distanceTypes as SelectOption[],
      })
    }

    if (!isFormLoading) {
      reset(setInitialValuesWithBackendData())
    }
  }, [
    awardAcceptanceDeadlinesOptions,
    currencies,
    extraChargeTypes,
    isFormLoading,
    modeOptions,
    rateTypes,
    reset,
    shipperData,
    shipperSettings,
    timePeriods,
    rfpBidTypesOptions,
    priceItemTypesEnabled,
    rfp,
  ])

  useEffect(() => {
    if (priceItemTypesEnabled && shipperSettings) {
      setValue(
        'accessorials',
        getInitialPriceItemTypes({
          priceItemTypes,
          shipperSettings,
        })
      )
    }
  }, [priceItemTypes, priceItemTypesEnabled, setValue, shipperSettings])

  useEffect(() => {
    if (rfp && shipperSettings) {
      const requestedAccessorials = getInitialRequestedAccessorials({
        priceItemTypesEnabled,
        priceItemTypes,
        extraChargeTypes,
        shipperSettings,
        rfp,
      })

      setValue('accessorials', requestedAccessorials)
    }
  }, [
    extraChargeTypes,
    priceItemTypes,
    priceItemTypesEnabled,
    rfp,
    setValue,
    shipperSettings,
  ])

  const setBidsDeadlineWithTime = (bidsDeadline: Date, time: string) => {
    setValue(
      'bidsDeadline',
      getDateWithUTCHours(bidsDeadline, getTimeSplitted(time))
    )

    /**
     * It was needed to trigger the validation manually,
     * because setValue usage instead of the Controller's onChange.
     * Otherwise Yup validation do not trigger in this case.
     */
    trigger('bidsDeadline')
  }

  useEffect(() => {
    if (watchCapacityTimePeriod?.value === 'spot') {
      setValue(
        'bidType',
        rfpBidTypesOptions.find(
          (option: SelectOption) => option.value === 'award'
        )
      )

      setFilteredBidTypeOptions(
        rfpBidTypesOptions.filter(
          (option: SelectOption) => option.value === 'award'
        )
      )
    } else {
      setFilteredBidTypeOptions(rfpBidTypesOptions)
    }
  }, [rfpBidTypesOptions, setValue, watchCapacityTimePeriod])

  useEffect(() => {
    function setDefaultAwardDeadlineIfIsNullAndPeriodIsSpot() {
      if (
        watchAwardAcceptanceDeadline === null &&
        watchCapacityTimePeriod?.value === 'spot'
      ) {
        setValue(
          'awardAcceptanceDeadline',
          getSelectedOption(
            DEFAULT_AWARD_ACCEPTANCE_DEADLINE_DAYS,
            awardAcceptanceDeadlinesOptions
          )
        )

        trigger('awardAcceptanceDeadline')
      }
    }

    setDefaultAwardDeadlineIfIsNullAndPeriodIsSpot()
  }, [
    awardAcceptanceDeadlinesOptions,
    setValue,
    trigger,
    watchAwardAcceptanceDeadline,
    watchCapacityTimePeriod?.value,
  ])

  useEffect(() => {
    function setEndDateAsStartDate() {
      if (
        new Date(watchContractStartDate).getTime() >
        new Date(watchContractEndDate).getTime()
      ) {
        setValue('contractEndDate', new Date(watchContractStartDate))
      }
    }

    setEndDateAsStartDate()
  }, [setValue, watchContractEndDate, watchContractStartDate])

  useEffect(() => {
    function validateIfBidsDeadlineIsAfterContractStartDate() {
      if (
        new Date(watchBidsDeadline).getTime() >
        new Date(watchContractStartDate).getTime()
      ) {
        setError('bidsDeadline', {
          type: 'max',
          message: 'Must be before the contract start',
        })
      } else {
        trigger('bidsDeadline')
      }
    }

    validateIfBidsDeadlineIsAfterContractStartDate()
  }, [setError, trigger, watchBidsDeadline, watchContractStartDate])

  const onSubmit = async (data: unknown) => {
    if (rfp) {
      await editRFP(
        rfp,
        data as FormValue,
        priceItemTypesEnabled,
        setHasCreateOrEditError,
        () => {
          queryClient.resetQueries(['shipperMember'], { exact: false })
          history.replace(`/shipper/rfp/${rfp.id}`)
        },
        queryClient
      )
    } else {
      const newRfp = await submitRFP(
        data as FormValue,
        priceItemTypesEnabled,
        setHasCreateOrEditError
      )

      if (newRfp) {
        queryClient.resetQueries(['shipperMember'], { exact: false })
        history.replace(`/shipper/rfp/${newRfp.id}`)
      }
    }
  }

  const handleCancel = useCallback(() => {
    analytics.track(AnalyticsEvent.CreateBidCancel)
    if (rfp?.id) {
      history.push(`/shipper/rfp/${rfp.id}`)
    } else {
      history.push('/shipper/rfp')
    }
  }, [history, rfp])

  if (isFormLoading) {
    return (
      <Layout.Stack align="center" justify="center">
        <Spinner size={36} aria-label="Loading" />
      </Layout.Stack>
    )
  }

  const isAccessorialLoading =
    extraChargesAreTypesAreLoading || priceItemTypesLoading

  const isFieldDisabled = checkIsDisabledField(rfp?.state)

  if (checkRenderNull(rfp?.state)) {
    return null
  }

  const submitButtonName = () => {
    return rfp ? 'Save RFP' : 'Create RFP'
  }

  return (
    <>
      <NewFormHeader
        rfp={rfp}
        shouldRender={true}
        cancelCreation={handleCancel}
        createRFP={handleSubmit(onSubmit)}
        isCreationEnable={isValid}
      />
      <Layout.Stack
        paddingT="spacing-10"
        gap="spacing-10"
        style={{ maxWidth: '748px', margin: '0 auto 40px auto' }}
      >
        <ErrorBanner hasError={hasCreateOrEditError} />
        <RFPDetailsSection
          shouldRender={true}
          control={control}
          setValue={setValue}
          isFieldDisabled={isFieldDisabled}
          errors={errors}
          distanceOptions={distanceTypes}
          currencyOptions={currencies}
          fuelInclusionOptions={rateTypes}
          watchCurrency={watchCurrency}
        />
        <RFPContractSection
          shouldRender={true}
          awardAcceptanceOptions={awardAcceptanceDeadlinesOptions}
          awardPreferenceOptionsWatch={watchBidType}
          awardPreferenceOptions={filteredBidTypeOptions}
          shippingFrequencyOptions={timePeriods}
          shippingFrequencyWatch={watchCapacityTimePeriod}
          isFieldDisabled={isFieldDisabled}
          control={control}
          errors={errors}
          rfpState={rfp?.state}
        />
        <RFPCarrierDeadlinesSection
          shouldRender={true}
          bidsSubmissionWatch={watchTime}
          getTimeSplitted={getTimeSplitted}
          setBidsDeadlineWithTime={setBidsDeadlineWithTime}
          getValues={getValues}
          control={control}
          errors={errors}
          rfpState={rfp?.state}
        />
        <RFPAccessorialsSection
          shouldRender={true}
          isAccessorialLoading={isAccessorialLoading}
          isFieldDisabled={isFieldDisabled}
          priceItemTypesEnabled={priceItemTypesEnabled}
          control={control}
        />
        <RFPContactsSection
          shouldRender={true}
          setValue={setValue}
          isFieldDisabled={isFieldDisabled}
          control={control}
          errors={errors}
        />
        <DocumentsSection
          shouldRender={true}
          fileCount={3}
          sizeLimitInMb={MAX_FILE_SIZE_MB}
          value={watchAttachments}
          onChange={handleShipperFilesChange}
          error={errors.attachments}
          setError={setError}
          isEditing={!!rfp}
        />
      </Layout.Stack>
      <Layout.Group
        style={{
          maxWidth: '748px',
          margin: '40px auto 40px auto',
          justifyContent: 'end',
        }}
      >
        <Button variant="secondary" type="button" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          loading={isSubmitting}
          variant="primary"
          type="submit"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {submitButtonName()}
        </Button>
      </Layout.Group>
    </>
  )
}

export default RFPForm
