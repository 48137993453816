import { IconCheckCircle as BaseIconCheckCircle } from '@loadsmart/icons'
import { Card, Dialog, Text } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import type { RequestForProposal } from 'rfp/rfp.types'
import { appRoutes } from 'router/app-routes'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

const IconCheckCircle = styled(BaseIconCheckCircle)`
  color: ${({ theme }) => theme.colors.main};
`

interface Props {
  readonly rfp: RequestForProposal
  readonly isOpen: boolean
  readonly onClose: () => void
}

const InfoList = styled.ul`
  font-size: 16px;
  margin: 0;
  padding-left: 16px;
  text-align: start;
`

export default function RFPAwardFinalizedDialog({
  rfp,
  isOpen,
  onClose,
}: Props) {
  const history = useHistory()

  const redirectToContracts = () => {
    analytics.track(
      AnalyticsEvent.RFPFinalizedDialogGoToContractsPage,
      AnalyticsEventTrigger.click
    )
    const params = new URLSearchParams({ title: rfp.name })

    history.push({
      pathname: appRoutes.contracts,
      search: params.toString(),
    })
  }

  return (
    <Dialog open={isOpen} onOverlayClick={onClose}>
      <Dialog.Header>RFP {rfp.name} is now finished</Dialog.Header>
      <Dialog.Body>
        <IconCheckCircle width={51} height={51} />
        <Text variant="body-bold" color="color-neutral-darker" as="p">
          You can now tender this RFP&apos;s lanes.
          <br />
        </Text>
        <Text variant="body" color="color-neutral-dark" as="p">
          This RFP was finished and a contract was created. Now you can view it
          and tender the lanes by going to the Contracts page.
        </Text>
        <Card.Separator />
        <Text variant="body-bold" color="color-neutral-darker" as="p">
          Also:
        </Text>
        <InfoList>
          <Text variant="body" color="color-neutral-dark" as="li">
            All award emails are being sent.
          </Text>
          <Text variant="body" color="color-neutral-dark" as="li">
            This RFP will still be available for future access and exporting it
            as CSV.
          </Text>
          <Text variant="body" color="color-neutral-dark" as="li">
            Carriers are not required to answer their emails. If you need any
            other info from them, please contact them directly with the emails
            on the carrier list.
          </Text>
        </InfoList>
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={redirectToContracts}>
        view it in contracts page
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onClose}>Close</Dialog.ActionCancel>
    </Dialog>
  )
}
