import type { Channel, Options } from 'pusher-js'
import Pusher from 'pusher-js/worker'

import { BASE_URL } from 'utils/constants'
import { getEnvVar } from 'utils/envVars'

export type PusherConfig = Omit<Options, 'channelAuthorization'> & {
  key: string
  channelAuthorization: NonNullable<Options['channelAuthorization']>
}

// Useful note: if error.data.code is 4004, then we're over our connections limits.
export type PusherConnectionError = {
  type: string
  error: {
    data: {
      code: number
      message: string
    }
    type: string
  }
}

export type PusherUnavailableError = {
  type: 'PusherUnavailableError'
  message: string
}

export type PusherNotSupportedError = {
  type: 'PusherNotSupportedError'
  message: string
}

export type PusherConnectionSuccess = {
  socket_id: string
}

export type PusherSubscriptionError = {
  type: string
  error: string
  status: number
}

export type WorkerError = {
  type: 'WorkerError'
  error: Error
}

export type ConfigurationError = {
  type: 'ConfigurationError'
  message: string
}

export type PusherError =
  | PusherConnectionError
  | PusherSubscriptionError
  | WorkerError
  | ConfigurationError
  | PusherUnavailableError
  | PusherNotSupportedError

export type OnReadyCallback = (data?: unknown) => void
export type OnErrorCallback = (error: PusherError) => void
export type OnMessageCallback<T extends string | string[] = string> = (
  event: T,
  data: unknown
) => void

export const CONNECTION_ERROR_EVENT = 'error'
export const CONNECTION_SUCCESS_EVENT = 'connected'
export const DISCONNECTION_EVENT = 'disconnected'
export const CONNECTION_STATE_CHANGE_EVENT = 'state_change'

export const SUBSCRIPTION_SUCCESS_EVENT = 'pusher:subscription_succeeded'
export const SUBSCRIPTION_ERROR_EVENT = 'pusher:subscription_error'
export const PUSHER_CONFIG: PusherConfig = {
  key: getEnvVar('PUSHER_KEY'),
  cluster: 'mt1',
  channelAuthorization: {
    endpoint: `${BASE_URL}/web_sockets/auth`,
    transport: 'ajax',
  },
}

export const PUSHER_DISCONNECTION_TIMEOUT = 5000

export function createPusherClient() {
  const options = PUSHER_CONFIG
  // passing the pusher as argument to allow flexibility between browser and worker sdk
  let pusher: Pusher | undefined
  const channels = new Map<string, Channel>()

  /** creates the pusher client instance
   * the sdk automatically attemps to connect after instantiation */
  function init(token: string) {
    const { key, ...config } = options

    if (Pusher.instances.length > 0) {
      pusher = Pusher.instances[0]
    } else if (typeof pusher === 'undefined') {
      pusher = new Pusher(key, {
        ...config,
        channelAuthorization: {
          ...config.channelAuthorization,
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${token}`,
          },
        },
      })
    }

    return pusher
  }

  const getPusher = () => {
    if (!pusher) {
      throw new Error(
        'PusherClient:: Pusher is not initialized. Call init() first'
      )
    }

    return pusher
  }

  /**
   * This function was written to please some SonarQube warnings
   * "Review this usage of "channels" as it can only be empty here."
   */
  const getChannel = (channelName: string) => {
    if (!channels.has(channelName)) {
      return undefined
    }

    return channels.get(channelName)
  }

  /**
   * Starts the connection with pusher
   */
  function connect() {
    pusher?.connect()
  }

  /** Disconnects from pusher */
  function disconnect() {
    pusher?.disconnect()
  }

  function status() {
    return pusher?.connection.state ?? 'disconnected'
  }

  /**
   * subscribe to a given channel
   */
  function addChannelListener(
    channelName: string,
    onSuccess?: (data: object) => void,
    onError?: (data: object) => void
  ) {
    const previousChannel = getChannel(channelName)
    if (previousChannel) {
      return previousChannel
    }

    const channel = getPusher().subscribe(channelName)
    channels.set(channelName, channel)

    if (onSuccess) {
      channel.bind('pusher:subscription_succeeded', onSuccess)
    }
    if (onError) {
      channel.bind('pusher:subscription_error', onError)
    }

    return channel
  }

  /**
   * unsubscribe from a given channel
   */
  function removeChannelListener(channelName: string) {
    const channel = getChannel(channelName)

    if (!channel) {
      throw new Error(`PusherClient:: You're not subscribed to ${channelName}`)
    }

    channel.unsubscribe()
    channels.delete(channelName)
  }

  /**
   * subscribe to a given channel event
   */
  function addEventListener(
    channelName: string,
    eventName: string,
    callback: (data: object) => void
  ) {
    const channel = getChannel(channelName)

    if (!channel) {
      throw new Error(`PusherClient:: You're not subscribed to ${channelName}`)
    }

    channel.bind(eventName, callback)
  }

  /**
   * unsubscribe from a given channel event
   */
  function removeEventListener(channelName: string, eventName: string) {
    const channel = getChannel(channelName)

    if (!channel) {
      throw new Error(`PusherClient:: You're not subscribed to ${channelName}`)
    }

    channel.unbind(eventName)
  }

  return {
    init,
    status,
    connect,
    addChannelListener,
    addEventListener,
    removeChannelListener,
    removeEventListener,
    disconnect,
  }
}
