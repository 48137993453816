import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { usePendingFulfillmentsAsCustomer } from 'fulfillments/hooks/usePendingFulfillments'
import { defaultTo, noop } from 'lodash'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'

import type { TruckMileageResponse } from 'orders/order-service'

import { useFulfillmentsPlan } from './hooks/useFulfillmentsPlan'
import type { FulfillmentsPlan } from './PlanFulfillmentsPage.types'

export interface PlanFulfillmentsContext {
  addSelectedFulfillments: (toBeAdded: string | string[]) => void
  canStartShipment: boolean
  clearQPState: () => void
  isLoadingPendingFulfillments?: boolean
  isLoadingSelectedFulfillments?: boolean
  isLoadingTruckMileage?: boolean
  onStartShipmentClick: () => void
  orderedFacilitiesUUIDs: string[]
  pendingFulfillments?: ListFulfillment[]
  plan?: FulfillmentsPlan
  removeSelectedFulfillments: (toBeRemoved: string | string[]) => void
  reorderFacility: (facility: string, direction: 'up' | 'down') => void
  selectableFulfillments: ListFulfillment[]
  selectedFulfillments: FulfillmentDetails[]
  selectedFulfillmentsUUIDs: string[]
  truckMileage?: TruckMileageResponse
}

export const DEFAULT_CONTEXT_STATE: PlanFulfillmentsContext = {
  addSelectedFulfillments: noop,
  canStartShipment: false,
  clearQPState: noop,
  onStartShipmentClick: noop,
  orderedFacilitiesUUIDs: [],
  plan: undefined,
  removeSelectedFulfillments: noop,
  reorderFacility: noop,
  selectableFulfillments: [],
  selectedFulfillments: [],
  selectedFulfillmentsUUIDs: [],
}

export const Context = createContext<PlanFulfillmentsContext>(
  DEFAULT_CONTEXT_STATE
)

function usePlanShipmentContextManager() {
  const { data, isLoading, isError } = usePendingFulfillmentsAsCustomer()

  const pendingFulfillments = defaultTo(data?.results, [])
  const isLoadingPendingFulfillments = isLoading

  const {
    addSelectedFulfillments,
    canStartShipment,
    clearQPState,
    isLoadingSelectedFulfillments,
    isLoadingTruckMileage,
    onStartShipmentClick,
    orderedFacilitiesUUIDs,
    plan,
    removeSelectedFulfillments,
    reorderFacility,
    setSelectedFulfillments,
    selectableFulfillments,
    selectedFulfillmentsUUIDs,
    selectedFulfillments,
    truckMileage,
  } = useFulfillmentsPlan(pendingFulfillments)

  useEffect(() => {
    if (isError) {
      toast.error('Failed to load pending fulfillments')
    }
  }, [isError])

  return {
    addSelectedFulfillments,
    canStartShipment,
    clearQPState,
    isLoadingPendingFulfillments,
    isLoadingSelectedFulfillments,
    isLoadingTruckMileage,
    onStartShipmentClick,
    orderedFacilitiesUUIDs,
    pendingFulfillments,
    plan,
    removeSelectedFulfillments,
    reorderFacility,
    setSelectedFulfillments,
    selectableFulfillments,
    selectedFulfillmentsUUIDs,
    selectedFulfillments,
    truckMileage,
  }
}

export function PlanFulfillmentsContextProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const context = usePlanShipmentContextManager()

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export function usePlanFulfillmentsContext() {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error(
      'usePlanFulfillmentsContext must be used within a PlanFulfillmentsContextProvider'
    )
  }

  return context
}
