import { defaultTo } from 'lodash'
import { useQuery } from 'react-query'

import {
  getListOrdersParams,
  listOrders,
  listOrdersAsSupplier,
  MAX_LIMIT,
} from 'orders/order-service'

export type UseFulfillableOrdersParams = {
  asSupplier?: boolean
  enabled?: boolean
}

export function useFulfillableOrders({
  asSupplier = false,
  enabled = true,
}: UseFulfillableOrdersParams = {}) {
  const params = getListOrdersParams({
    limit: MAX_LIMIT,
    offset: 0,
    search_term: null,
    status: ['open', 'planning'],
  })

  const { data, isError, isLoading } = useQuery({
    queryKey: ['getPlannableOrders', asSupplier, params],
    queryFn: () =>
      asSupplier
        ? listOrdersAsSupplier({
            ...params,
          })
        : listOrders({
            ...params,
          }),
    cacheTime: 60000,
    enabled,
  })

  const orders = defaultTo(data?.results, [])

  return {
    isError,
    isLoading,
    orders,
  }
}
