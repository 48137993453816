import type { TransientFulfillment } from 'fulfillments/domain/Fulfillment'

import { useFeatureFlag } from '_shared_/feature-flag'

export function useFulfillmentCreationFeatureFlags() {
  const disableFulfillmentsWithoutOrders = useFeatureFlag(
    'DISABLE_FULFILLMENTS_WITHOUT_ORDERS'
  )
  const enableFulfillmentsWithoutOrders = !disableFulfillmentsWithoutOrders
  const enablePOAsDefaultInputMode = useFeatureFlag(
    'ENABLE_DEFAULT_ORDER_INPUT_AS_PO'
  )

  const defaultOrderInputMode: TransientFulfillment['order_identifier_type'] =
    enableFulfillmentsWithoutOrders && enablePOAsDefaultInputMode
      ? 'po_number'
      : 'orders'

  return {
    defaultOrderInputMode,
    enableFulfillmentsWithoutOrders,
  }
}
